import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '../../../../app/config/config';
import { Location } from '@angular/common';
import { Product } from '../../../model/Product';
import { ProductService } from '../../../services/product.services';
import { ToastrService } from 'ngx-toastr';
import { ListComponentInterface, ListActions } from 'src/app/components/lists/list.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: '../../../components/common/templates/app-list-template.html',
  styleUrls: ['../../../styles/main.scss']
})
export class ListProductComponent implements ListComponentInterface {
  actions: ListActions[];
  activateSeeker: boolean;
  activateSelectParent: boolean;
  columns: any;
  current: any;
  data: Array<Product>;
  deleteAlertMessage: string;
  id: any = null;
  open: boolean;
  page: number;
  parentData?: Array<any>;
  parent?: string;
  tableId: string;
  title: string;

  constructor(
    private location: Location,
    private productService: ProductService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.actions = [ ListActions.update, ListActions.delete ];
    this.activateSeeker = true;
    this.activateSelectParent = false;
    this.columns = ['uuid', 'published', 'name', 'moq', 'salePrice', 'brand', 'codeHS', 'category'];
    this.deleteAlertMessage = 'sure_delete_product';
    this.open = false;
    this.tableId = 'uuid';
    this.title = 'products';
    this.getProduct();
  }

  getProduct(page?, keyword?) {
    // tslint:disable-next-line: no-unused-expression
    page && (this.page = page);
    this.productService.getMyProducts(page, 10, keyword).subscribe((data) => {
      this.current = data.data;
      this.data = data.data.products;
      this.data.map((obj) => {
        obj.countryManufacturing = (obj.manufacturingCity) ? obj.manufacturingCity.country.name : null;
        obj.countryCellar = (obj.countryCellar) ? obj.countryCellar.name : null;
        obj.unit = (obj.unit) ? obj.unit.name : null;
        obj.shippingType = (obj.shippingType) ? obj.shippingType.name : null;
        obj.payMode = (obj.payMode) ? obj.payMode.name : null;
        obj.category = (obj.category) ? obj.category.name : null;
      });
    });
  }

  pageCurrent(page) {
    this.getProduct(page);
  }

  create() {}

  async update(uuid) {
    const route = this.location.path().replace('/', '').split('?').shift();
    await localStorage.setItem(Config.returnUrl, route);
    this.router.navigate([Config.router.createProduct], { queryParams: { uuid } });
  }

  delete(obj: {id: any, confirmed: boolean}) {
    this.id = (obj.id) ? obj.id : this.id;
    if (obj.confirmed) {
      this.productService.editProduct({ isDeleted: true }, this.id).subscribe((put) => {
        this.getProduct(this.page);
        this.toastr.success(this.translate.instant('deleted_product'));
      });
    } else {
      this.open = !this.open;
    }
  }

  view(id: string | number) { }

  search(keyword: string) {
    this.getProduct(this.page, keyword);
  }

}
