import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(
    private httpClient: HttpClient
  ) { }
  chatOne(uuid): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.thread + `/${uuid}`);
  }
  chats(): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.myChats);
  }
  messages(uuid): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.getMsg + uuid);
  }
  addMessages(uuid, body): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.addMsg + uuid, body);
  }

}
