import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShippingsService {
  constructor(
    private httpClient: HttpClient
  ) { }

  getShippingTypes(): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.shippings);
  }
  createShipping(body): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.shipping, body);
  }
  updateShipping(body): Observable<any> {
    return this.httpClient.put(Config.api + Config.endpoints.shipping, body);
  }
  getShipping(id): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.shipping + `/order/${id}`);
  }

}
