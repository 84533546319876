import { BaseComponent } from '../views/base/base.component';
import { Config } from '../config/config';
import { CreateCategoryComponent } from '../views/admin/categories/create/createCategory.component';
import { CreateProductComponent } from '../views/products/create/createProduct.component';
import { CreateUnitComponent } from '../views/admin/units/create/createUnit.component';
import { ListCategoriesComponent } from '../views/admin/categories/list/listCategories.component';
import { ListCitiesComponent } from '../views/admin/cities/list/listCities.component';
import { ListCountriesComponent } from '../views/admin/countries/list/listCountries.component';
import { ListProductComponent } from '../views/products/list/listProduct.component';
import { ListUnitsComponent } from '../views/admin/units/list/listUnits.component';
import { LoginPageComponent } from '../login-page/login-page.component';
import { RegisterComponent } from '../register/register.component';
import { Routes } from '@angular/router';
import { from } from 'rxjs';
import { CreateCountryComponent } from '../views/admin/countries/create/createCountry.component';
import { CreateCityComponent } from '../views/admin/cities/create/createCity.component';
import { HomeComponent } from '../views/products/home/home.component';
import { DetailsProductComponent } from '../views/products/details/details.component';
import { ListTagsComponent } from '../views/admin/tags/list/listTags.component';
import { CreateTagComponent } from '../views/admin/tags/create/createTag.component';
import { ListOrdersComponent } from '../views/orders/list/listOrders.component';
import { ChatComponent } from '../views/chat/chat.component';
import { ResultComponent } from '../views/products/result/result.component';
import { ProfileComponent } from '../views/profile/profile.component';
import { ListUsersComponent } from '../views/users/list/userList.component';
import { CreateUserComponent } from '../views/users/create/createUser.component';
import { FavoriteComponent } from '../views/products/favorite/favorite.component';
import { OrderViewComponent } from '../views/orders/view/orderView.component';

export class RoutersMain {
  public static appRoutes: Routes = [
    {
      path: '',
      component: BaseComponent,
      children: [
        {
          path: '',
          component: HomeComponent
        },
        {
          path: Config.router.createProduct,
          component: CreateProductComponent
        },
        {
          path: Config.router.listProduct,
          component: ListProductComponent
        },
        {
          path: Config.router.createCategory,
          component: CreateCategoryComponent
        },
        {
          path: Config.router.listCategories,
          component: ListCategoriesComponent
        },
        {
          path: Config.router.createUnit,
          component: CreateUnitComponent
        },
        {
          path: Config.router.listUnits,
          component: ListUnitsComponent
        },
        {
          path: Config.router.listCountries,
          component: ListCountriesComponent
        },
        {
          path: Config.router.createCountry,
          component: CreateCountryComponent
        },
        {
          path: Config.router.listCities,
          component: ListCitiesComponent
        },
        {
          path: Config.router.createCity,
          component: CreateCityComponent
        },
        {
          path: Config.router.detailsProduct,
          component: DetailsProductComponent
        },
        {
          path: Config.router.listTags,
          component: ListTagsComponent
        },
        {
          path: Config.router.createTag,
          component: CreateTagComponent
        },
        {
          path: Config.router.ordersGenerated,
          component: ListOrdersComponent
        },
        {
          path: Config.router.ordersReceived,
          component: ListOrdersComponent
        },
        {
          path: Config.router.chat,
          component: ChatComponent
        },
        {
          path: Config.router.result,
          component: ResultComponent
        },
        {
          path: Config.router.profile,
          component: ProfileComponent
        },
        {
          path: Config.router.listUsers,
          component: ListUsersComponent
        },
        {
          path: Config.router.createUser,
          component: CreateUserComponent
        },
        {
          path: Config.router.wish_list,
          component: FavoriteComponent
        },
        {
          path: Config.router.orderDetail,
          component: OrderViewComponent
        },
      ]
    },
    {
      path: Config.router.register,
      component: RegisterComponent
    },
    {
      path: Config.router.login,
      component: LoginPageComponent
    },
  ];
}

