import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  constructor(
    private httpClient: HttpClient
  ) { }
  add(product): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.wish_list, product);
  }

  get(): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.wish_list);
  }

  delete(product): Observable<any> {
    return this.httpClient.delete(Config.api + Config.endpoints.wish_list, product);
  }

}
