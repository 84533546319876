import { ListComponentInterface, ListActions } from 'src/app/components/lists/list.component';
import { Location } from '@angular/common';
import { Tag } from 'src/app/model/Tag';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { OnInit, Component } from '@angular/core';
import { TagService } from '../../../../services/tag.service';
import { Config } from 'src/app/config/config';
import { CategoryService } from 'src/app/services/categories.services';

@Component({
  templateUrl: '../../../../components/common/templates/app-list-template.html',
  styleUrls: ['../../../../styles/main.scss']
})
export class ListTagsComponent implements OnInit, ListComponentInterface {
  actions: ListActions[];
  activateSeeker: boolean;
  activateSelectParent: boolean;
  childrensKey: string;
  columns: any;
  current: any;
  data: Array<Tag>;
  deleteAlertMessage: string;
  groupSelectOptions: boolean;
  id: any;
  open: boolean;
  page: number;
  parent?: any;
  parentData?: any[];
  tableId: string;
  title: string;

  constructor(
    private categoryService: CategoryService,
    private tagService: TagService,
    private location: Location,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.actions = [ListActions.create, ListActions.update, ListActions.delete];
    this.activateSeeker = true;
    this.activateSelectParent = true;
    this.columns = ['name'];
    this.deleteAlertMessage = 'sure_delete_tag';
    this.open = false;
    this.tableId = 'id';
    this.title = 'tags';
    this.groupSelectOptions = true;
    this.childrensKey = 'subCategories';
  }

  async ngOnInit() {
    this.categoryService.getAllSubcategories().subscribe(async data => {
      this.parentData = data.data.map(category => {
        const subCategories = category.subCategories.map(subcategory => {
          return { value: subcategory.id, name: subcategory.name };
        });
        return {...category, subCategories };
      });
    });
  }

  create() {
    this.update(null);
  }

  delete(obj: { id: any, confirmed: boolean }) {
    this.id = (obj.id) ? obj.id : this.id;
    if (obj.confirmed) {
      this.tagService.delete(this.id).subscribe((put) => {
        this.getTags(this.parent, this.page);
        this.toastr.success(this.translate.instant('deleted_tag'));
      });
    } else {
      this.open = !this.open;
    }
  }

  pageCurrent(page) {
    this.getTags(this.parent, page);
  }

  parentSelected(id: number) {
    this.parent = id;
    this.getTags(this.parent);
  }

  getTags(categoryId: number, page?: number, take?: number, keyword?: any) {
    // tslint:disable-next-line: no-unused-expression
    page && (this.page = page);
    this.tagService.getAll(categoryId).subscribe(data => {
      this.data = data.data;
      this.current = data;
    });
  }

  async update(id) {
    const route = this.location.path().replace('/', '').split('?').shift();
    localStorage.setItem(Config.returnUrl, route);
    this.router.navigate([Config.router.createTag], { queryParams: { id } });
  }
}
