import { CreationComponentInterface } from 'src/app/components/componentCreation/creation.component';
import { Component, OnInit } from '@angular/core';
import { CountryService } from 'src/app/services/country';
import { City } from 'src/app/model/City';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Country } from 'src/app/model/Country';

@Component({
  templateUrl: '../../../../components/common/templates/create.component.html',
  styleUrls: ['../../../../styles/main.scss'],
  providers: [CountryService]
})
export class CreateCityComponent implements OnInit, CreationComponentInterface {
  buttonPosition: any;
  columns: any;
  data: City;
  formantView: number;
  rules: any;
  title: string;
  validateInputs: boolean;
  instance: any;
  id: string | number;

  countries: any[];

  constructor(
    private countryService: CountryService,
    private location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.columns = [
      {
        name: 'countryId',
        type: 'select',
        data: [],
      },
      {
        name: 'name',
        type: 'input',
      },
    ];
    this.getCountries();
    this.id = this.route.snapshot.queryParamMap.get('id');
    if (this.id) {
      this.title = 'update_city';
      this.countryService.getCity(this.id).subscribe((data: { data }) => {
        this.instance = data.data;
      });
    } else {
      this.title = 'create_city';
    }
  }

  async ngOnInit() {
    //await this.getCountries();
  }

  async getCountries() {
    this.countryService.getCountries(null, null, null, null).subscribe(async data => {
      this.columns[0].data = await data.data.data.map((country: Country) => {
        return { value: country.id, name: country.name };
      });
    });
  }

  save(data: Partial<City>) {
    if (!this.id) {
      this.countryService.saveCity(data).subscribe(unit => {
        this.toastr.success(this.translate.instant('city_created'));
        this.location.back();
      }, (error) => this.toastr.error(error.message));
    } else {
      this.countryService.updateCity(data, this.id).subscribe(unit => {
        this.toastr.success(this.translate.instant('updated_city'));
        this.location.back();
      }, (error) => this.toastr.error(error.message));
    }
  }
}
