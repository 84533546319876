import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortsService {
  constructor(
    private httpClient: HttpClient
  ) { }

  get(): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.port);
  }
  add(body): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.port, body);
  }
  getOne(id: number): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.port + `/${id}`);
  }

}
