export class Config {
  public static api = 'http://ec2-18-220-43-63.us-east-2.compute.amazonaws.com:8080/';
  public static endpoints = {
    subcategories: 'categories/sub',
    activate: 'auth/activate',
    categories: 'categories',
    companies: 'companies',
    countries: 'countries',
    createCompany: 'companies/my-company',
    login: 'auth/login',
    my_products: 'products/my-products/',
    new_code: 'auth/new_code',
    paymentType: 'payment-type',
    plans: 'plans',
    products: 'products/',
    register: 'auth/register',
    tags: 'tags',
    shippings: 'shippings/types',
    units: 'units',
    uploadDoc: 'attachments/upload/document',
    uploadImage: 'attachments/upload/photo',
    users: 'users/',
    usersTypesAll: 'users/types/all',
    ordersReceived: 'orders/received',
    ordersGenerated: 'orders/generated',
    ordersReceivedById: 'orders/:id/received',
    ordersGeneratedById: 'orders/:id/generated',
    wish_list: 'wish-list',
    shipping: 'shippings',
    port: 'port',
    thread: 'thread',
    threadContact: 'thread/contact-about-product',
    myChats: 'thread/my-chats',
    getMsg: 'thread/get-messages/',
    addMsg: 'thread/push-message/'
  };
  public static attachments = Config.api + 'attachments/';
  public static token = 'token';
  public static paramsQuery = 'params';
  public static localName = 'token';
  public static returnUrl = 'returnUrl';
  public static keyCryto = 'goodpandamd52812';
  public static lang = 'lang';
  public static router = {
    createCategory: 'categories/create',
    createCity: 'cities/create',
    createCountry: 'countries/create',
    createProduct: 'products/create',
    createTag: 'tags/create',
    createUnit: 'units/create',
    createUser: 'users/create',
    detailsProduct: 'products/details',
    listCategories: 'categories',
    listCities: 'cities',
    listCountries: 'countries',
    listProduct: 'products',
    listTags: 'tags',
    listUnits: 'units',
    listUsers: 'users',
    login: 'login',
    main: '/',
    register: 'register',
    ordersGenerated: 'orders/generated',
    ordersReceived: 'orders/received',
    chat: 'chats',
    result: 'result',
    profile: 'profile',
    wish_list: 'wish-list',
    invoice: 'invoice',
    orderDetail: 'orderDetail',
  };
  public static urlAttachments = Config.api + 'attachments/';
  public static md5Key = 'goodpanda2812';
}
