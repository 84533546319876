import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { QueryBuilder } from './QueryBuilder';
import { Country } from '../model/Country';
import { City } from '../model/City';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
    private httpClient: HttpClient
  ) { }

  delete(id: number, countryId?: number) {
    if (countryId) {
      return this.httpClient.delete(Config.api + Config.endpoints.countries + '/cities/' + id);
    } else {
      return this.httpClient.put(Config.api + Config.endpoints.countries + '/' + id, { active: false, id });
    }
  }

  getCountries(page = 1, take = 10, keyword?: any, active?: boolean): Observable<any> {
    let objQuery: any = { page, take };
    // tslint:disable-next-line: no-unused-expression
    keyword && (objQuery = {...objQuery, keyword });
    // tslint:disable-next-line: no-unused-expression
    active && (objQuery = {...objQuery, active });

    const params: HttpParams = QueryBuilder.queryToHttpParams(QueryBuilder.verifyObject(objQuery));
    return this.httpClient.get(Config.api + Config.endpoints.countries, {params});
  }

  getCities(id: number, page = 1, take = 10, keyword?: any): Observable<any> {
    let objQuery: any = { page, take };
    // tslint:disable-next-line: no-unused-expression
    keyword && (objQuery = {...objQuery, keyword });

    const params: HttpParams = QueryBuilder.queryToHttpParams(QueryBuilder.verifyObject(objQuery));
    return this.httpClient.get(Config.api + Config.endpoints.countries + '/' + id + '/cities', {params});
  }

  getCity(cityId) {
    return this.httpClient.get(Config.api + Config.endpoints.countries + '/cities/' + cityId);
  }

  getOne(id: number): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.countries + '/' + id);
  }

  update(country: Partial<Country>, id?: number) {
    return this.httpClient.put(Config.api + Config.endpoints.countries + '/' + id, country);
  }

  save(country: Partial<Country>) {
    return this.httpClient.post(Config.api + Config.endpoints.countries, country);
  }

  updateCity(city: Partial<City>, id?: number | string) {
    return this.httpClient.put(Config.api + Config.endpoints.countries + '/cities/' + id, city);
  }

  saveCity(city: Partial<City>) {
    return this.httpClient.post(Config.api + Config.endpoints.countries + '/add-city', city);
  }

}
