import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '../../../../app/config/config';
import { Location } from '@angular/common';
import { Product } from '../../../model/Product';
import { ProductService } from '../../../services/product.services';
import { ToastrService } from 'ngx-toastr';
import { ListComponentInterface, ListActions } from 'src/app/components/lists/list.component';
import { TranslateService } from '@ngx-translate/core';
import { FavoriteService } from 'src/app/services/favorite.services';

@Component({
  templateUrl: '../../../components/common/templates/app-list-template.html',
  styleUrls: ['../../../styles/main.scss'],
  providers: [FavoriteService],
})
export class FavoriteComponent implements ListComponentInterface {
  actions: ListActions[];
  activateSeeker: boolean;
  activateSelectParent: boolean;
  columns: any;
  current: any;
  data: any;
  deleteAlertMessage: string;
  id: any = null;
  open: boolean;
  page: number;
  parentData?: Array<any>;
  parent?: string;
  tableId: string;
  title: string;

  constructor(
    private location: Location,
    private favoriteService: FavoriteService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.actions = [ ListActions.view, ListActions.delete ];
    this.activateSeeker = true;
    this.activateSelectParent = false;
    this.columns = ['name', 'moq', 'salePrice', 'stockCurrent', 'brand', 'category'];
    this.deleteAlertMessage = 'sure_delete_product';
    this.open = false;
    this.tableId = 'uuid';
    this.title = 'wish_list';
    this.get();
  }

  get(page?, keyword?) {
    // tslint:disable-next-line: no-unused-expression
    page && (this.page = page);
    this.favoriteService.get().subscribe((data) => {
      this.current = data.data;
      this.data = data.data;
      this.data.map((obj, index) => {
        obj.product.countryManufacturing = (obj.product.manufacturingCity) ? obj.product.manufacturingCity.country.name : null;
        obj.product.countryCellar = (obj.product.countryCellar) ? obj.product.countryCellar.name : null;
        obj.product.unit = (obj.product.unit) ? obj.product.unit.name : null;
        obj.product.shippingType = (obj.product.shippingType) ? obj.product.shippingType.name : null;
        obj.product.payMode = (obj.product.payMode) ? obj.product.payMode.name : null;
        obj.product.category = (obj.product.category) ? obj.product.category.name : null;
        Object.keys(obj.product).map(function (value, key) {
          obj[value] = obj.product[value];
        });
      });
    });
  }

  pageCurrent(page) {
    this.get(page);
  }

  create() {}

  async update(uuid) {}

  delete(obj: {id: any, confirmed: boolean}) {
    this.id = (obj.id) ? obj.id : this.id;

    if (obj.confirmed) {
      const product = this.data.map((data) => {
        return data.product.uuid === this.id;
      })
      this.favoriteService.delete(product).subscribe((data) => {
        this.get(this.page);
        this.toastr.success(this.translate.instant('deleted_product'));
      });
    } else {
      this.open = !this.open;
    }
  }

  view(uuid: string | number) {
    this.router.navigate([Config.router.detailsProduct], { queryParams: { uuid } });
  }

  search(keyword: string) {
    this.get(this.page, keyword);
  }

}
