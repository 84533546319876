import { Company } from './CompanyModel';
import { UserType } from './UserType';
import { Plan } from './Plan';
import { Model } from './Model';

export class User extends Model {
  public id: number;
  public uuid: string;
  public name: string;
  public lastname: string;
  public username: string;
  public email: string;
  public phone: string;
  public position: string;
  public deduction: string;
  public imgUrl: string;
  public password: string;
  public activeToken: string;
  public active: boolean;
  public isDeleted: boolean;
  public createdAt: Date;
  public userType: UserType;
  public company: Company;
  public plan: Plan;
  public repeatPassword: string;
}
