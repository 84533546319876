import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { QueryBuilder } from './QueryBuilder';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private httpClient: HttpClient
  ) { }

  register(data): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.register, data);
  }

  usersGet(uuid): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.users + uuid);
  }

  getAll(page: number, take: number, keyword: string) {
    let objQuery: any = { page, take };
    // tslint:disable-next-line: no-unused-expression
    keyword && (objQuery = { ...objQuery, keyword });
    const params: HttpParams = QueryBuilder.queryToHttpParams(QueryBuilder.verifyObject(objQuery));
    return this.httpClient.get(Config.api + Config.endpoints.users, {params});
  }

  create(data): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.users, data);
  }

  update(data) {
    return this.httpClient.put(Config.api + Config.endpoints.users, data);
  }

  getUserTypes() {
    return this.httpClient.get(Config.api + Config.endpoints.usersTypesAll);
  }

}
