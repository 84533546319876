import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ValidationPassword } from 'src/app/services/validations.services';
import { User } from 'src/app/model/UserModel';

export interface CreationComponentInterface {
  buttonPosition: any;
  columns: any;
  data: any;
  formantView: number;
  id: number | string;
  instance: any;
  rules: any;
  title: string;
  validateInputs: boolean;
}

@Component({
  selector: 'app-create-component',
  templateUrl: './creation.component.html',
  styleUrls: ['../../styles/main.scss'],
})
export class CreationComponent implements OnInit, CreationComponentInterface {
  id = null;
  instance = null;
  @Input() buttonPosition: any;
  @Input() columns: any;
  @Input() data: any;
  @Input() formantView: number;
  @Input() rules: any;
  @Input() title: string;
  @Input() validateInputs: boolean;
  @Output() submit = new EventEmitter();
  zxcvbnPassword: any;
  capitalLetters: any;
  lowerCasePass: any;
  passwordNumber: any;
  passwordSimbol: any;
  constructor(private validationPass: ValidationPassword) { }

  async ngOnInit() {
    this.formantView = (this.formantView ? this.formantView : 4);
    this.buttonPosition = (this.buttonPosition ? this.buttonPosition : 'left');

    if (Object.keys(this.columns).length) {
      await this.columns.map((value) => {
        if (!this.data) {
          this.data = {};
          this.data[value.name] = '';
        }
        if (!this.rules) {
          this.rules = {};
          this.rules[value.name] = {
            required: false,
            pattern: ''
          };
        } else if (!this.rules[value.name]) {
          this.rules[value.name] = {
            required: true,
            pattern: ''
          };
        }
      });
      this.validate();
    }
  }

  validate() {
    const data = this.data;
    const rules = this.rules;
    let validateInputs = false;
    const array = [];
    Object.keys(rules).map(function (value) {
      validateInputs = ((rules[value].pattern) ? rules[value].pattern.test(data[value]) : true &&
      (rules[value].required) ?  Boolean((data[value]) ? data[value].length : false) : true);
      array.push(validateInputs);
    });
    this.validateInputs = array.includes(false);
  }

  submitEmit(page) {
    this.submit.emit(page);
  }

  validationPassword(password) {
    this.validationPass.validationPassword(password).then((validations: any) => {
      this.zxcvbnPassword = validations.passwordValidation;
      this.capitalLetters = validations.capitalLetters;
      this.lowerCasePass = validations.LowerCase;
      this.passwordNumber = validations.passwordNumber;
      this.passwordSimbol = validations.passwordSimbol;
    });
  }
}
