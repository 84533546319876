import { Component,
        Input,
        Output,
        DoCheck,
        EventEmitter, ViewChild, ElementRef, KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { interval } from 'rxjs';
import { timeout } from 'rxjs/operators';

const seconds = interval(1000);
@Component({
  selector: 'app-alert-component',
  templateUrl: './alertModal.component.html',
  styleUrls: ['../../styles/main.scss']
})
export class AlertModalComponent implements DoCheck {
  @Input() title: string;
  @Input() message: string;
  @Input() customer: boolean;
  @Input() id: any;
  @Output() confirmEvent = new EventEmitter();
  @ViewChild('template') elementView: ElementRef;
  modalRef: BsModalRef;
  customerDiffer: KeyValueDiffer<string, any>;
  currentValue: any;
  constructor(private router: Router, private modalService: BsModalService, private differs: KeyValueDiffers) {
    const customer = this.customer;
    this.customerDiffer = this.differs.find({customer}).create();
    this.currentValue = 0;
  }
  customerChanged(changes: KeyValueChanges<string, any>) {
    changes.forEachChangedItem((r) => {
      this.currentValue = r.currentValue;
      setTimeout(() => {
        if (this.title && this.message) {
          this.open();
        }
      }, 10);
    });
  }
  ngDoCheck(): void {
    const customer = this.customer;
    const changes = this.customerDiffer.diff({customer});
    if (changes) {
      this.customerChanged(changes);
    }
  }
  confirm() {
    const event = {confirmed: true};
    this.confirmEvent.emit(event);
    this.modalRef.hide();
  }
  open() {
    this.modalRef = this.modalService.show(this.elementView);
  }
}
