import { Injectable } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';

@Injectable()
export class GlobalState {

  public replaySubject = new ReplaySubject();
  objEvent: any = new Object();
  constructor() {
  }
  replaySubjectSubscribe(nameEvent) {
    return this.objEvent[nameEvent] = new ReplaySubject();
  }
  replaySubjectChanged(nameEvent, value) {
    return this.objEvent[nameEvent].next(value);
  }
}
