import { Model } from './Model';
import { Company } from './CompanyModel';
import { OrderItems } from './OrderItems';
import { User } from './UserModel';
import { Attachment } from './Attachment';
import { Currency } from './Currency';

export class Order extends Model {
    public id: number;
    public priceWithoutIva: number;
    public priceWithIva: number;
    public paidAmount: number;
    public paymentType: string;
    public companySeller: Company;
    public state: any;
    public orderItems: OrderItems;
    public currency: Currency;
    public company: Company;
    public purchaseUser: User;
    public attachments: Attachment;
}
