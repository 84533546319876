

import { Component, OnInit } from '@angular/core';
import { CountryService } from '../../../../services/country';
import { ListComponentInterface, ListActions } from '../../../../components/lists/list.component';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Country } from '../../../../model/Country';
import { Config } from 'src/app/config/config';

@Component({
  templateUrl: '../../../../components/common/templates/app-list-template.html',
  styleUrls: ['../../../../styles/main.scss']
})
export class ListCitiesComponent implements OnInit, ListComponentInterface {
  actions: ListActions[];
  activateSeeker: boolean;
  activateSelectParent: boolean;
  columns: any;
  current: any;
  data: any;
  deleteAlertMessage: string;
  id: any;
  open: boolean;
  page: number;
  parent?: number;
  parentData?: Array<any>;
  tableId: string;
  title: string;

  constructor(
    private countryService: CountryService,
    private location: Location,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.actions = [ListActions.create, ListActions.update, ListActions.delete];
    this.activateSeeker = true;
    this.activateSelectParent = true;
    this.columns = ['name'];
    this.deleteAlertMessage = 'sure_delete_city';
    this.open = false;
    this.tableId = 'id';
    this.title = 'cities';
  }

  async ngOnInit() {
    this.countryService.getCountries(null, null, null, null).subscribe(async data => {
      this.parentData = await data.data.data.map((country: Country) => {
        return { value: country.id, name: country.name };
      });
    });
  }

  getCities(countryId?: number, page?, take?, keyword?: any) {
    // tslint:disable-next-line: no-unused-expression
    page && (this.page = page);
    this.countryService.getCities(countryId, page, take, keyword).subscribe(data => {
      this.data = data.data.data;
      this.current = data.data;
    });
  }

  create() {
    this.update(null);
  }

  delete(obj: { id: any, confirmed: boolean }) {
    this.id = (obj.id) ? obj.id : this.id;
    if (obj.confirmed) {
      this.countryService.delete(this.id, this.parent).subscribe((put) => {
        this.getCities(this.parent, this.page);
        this.toastr.success(this.translate.instant('deleted_city'));
      });
    } else {
      this.open = !this.open;
    }
  }

  pageCurrent(page) {
    this.getCities(this.parent, page);
  }

  parentSelected(id: number) {
    this.parent = id;
    this.getCities(this.parent);
  }

  search(keyword: string) {
    this.getCities(this.parent, null, 10, keyword);
  }

  async update(id) {
    const route = this.location.path().replace('/', '').split('?').shift();
    localStorage.setItem(Config.returnUrl, route);
    this.router.navigate([Config.router.createCity], { queryParams: { id } });
  }

  view(id: string | number) { }
}
