import {Component, OnInit, Input, Output, ViewChild, ElementRef} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Product } from '../../../model/Product';
import { ProductService } from '../../../services/product.services';
import { Config } from '../../../config/config';
import { Router, ActivatedRoute } from '@angular/router';
import { CategoryService } from 'src/app/services/categories.services';
import { GlobalState } from 'src/app/services/events.service';
import { QueryBuilder } from 'src/app/services/QueryBuilder';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['../../../styles/main.scss']
})
export class ResultComponent implements OnInit {
  products: any;
  modalRef: BsModalRef;
  @ViewChild('template') elementView: ElementRef;
  urlBack: string;
  categories: any;
  oneProduct: any;
  imgProduct: any;
  quantity: any;
  search: any;
  categoriyId: any;
  SubCategoriyId: any;
  limit: any;
  page: any;
  min: any;
  max; any;
  constructor(
    private modalService: BsModalService,
    private productService: ProductService,
    private router: Router,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    public _state: GlobalState,) {
      this.search = this.route.snapshot.queryParamMap.get('search');
      this.categoriyId = this.route.snapshot.queryParamMap.get('category');
      this.SubCategoriyId = this.route.snapshot.queryParamMap.get('Subcategory');
      this.min = this.route.snapshot.queryParamMap.get('min');
      this.max = this.route.snapshot.queryParamMap.get('max');
      this.limit = 20;
  }
  ngOnInit() {
    this.urlBack = Config.api + 'attachments/';
    this.categoryService.get().subscribe((data) => {
      this.categories = data.data;
      this.categories.map((category, index) => {
        this.categoryService.getSubcategoriesByCategory(category.id).subscribe((sub) => {
          category.subcategories = sub.data.subCategories;
        });
      });
    });
    this.product(1, this.limit, this.search, this.categoriyId, this.SubCategoriyId);
    this.eventSearch('search');
    this.eventSearch('category');
    this.eventSearch('Subcategory');
  }
  eventSearch(string) {
    this._state.replaySubjectSubscribe(string).subscribe((id) => {
      this.SubCategoriyId = id;
      this.addQueryURL();
      this.product(1, this.limit, null, this.categoriyId, this.SubCategoriyId);
    });
  }
  operationQuantity(operation) {
    if (operation) {
      this.quantity = Number(this.quantity) + 1;
    } else {
      this.quantity = Number(this.quantity) - 1;
    }
  }
  productPrice(min?, max?) {
    this.min = min ? min : null;
    this.max = max ? max : null;
    this.addQueryURL();
    this.product(1, this.limit, this.search, this.categoriyId, this.SubCategoriyId, min, max);
  }
  product(page, take, search?, categoryId?, subCategoryId?, min?, max?) {
    this.productService.getProducts(page, take, search, categoryId, subCategoryId, min, max).subscribe((data) => {
      this.products = data.data;
    });
  }
  addQueryURL() {
    const urlTree = this.router.createUrlTree([], {
      queryParams: {min: this.min, max: this.max, search: this.search, Subcategory: this.categoriyId, category: this.SubCategoriyId},
      queryParamsHandling: 'merge',
      preserveFragment: true });
    this.router.navigateByUrl(urlTree);
  }
  paginate(page) {
    this.page = page;
    this.product(this.page, this.limit, this.search, this.categoriyId, this.SubCategoriyId);
  }
  detailsProduct(uuid) {
    this.router.navigate([Config.router.detailsProduct], { queryParams: { uuid } });
  }
  selectCategory(id) {
    this.router.navigate([Config.router.result], {queryParams: {category: id}});
    this._state.replaySubjectChanged('category', id);
  }
  selectSubCategory(id) {
    this.router.navigate([Config.router.result], {queryParams: {Subcategory: id}});
    this._state.replaySubjectChanged('Subcategory', id);
  }
  open(uuid) {
    this.productService.getProduct(uuid).subscribe((data) => {
      this.oneProduct = data.data;
      if (this.oneProduct.productAttachments.length) {
        this.imgProduct = this.oneProduct.productAttachments[0].url;
      }
      this.modalRef = this.modalService.show(
        this.elementView,
        Object.assign({}, { class: 'gray modal-lg' })
      );
    });

  }
}
