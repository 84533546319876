import { Component } from '@angular/core';
import { ListComponentInterface, ListActions } from 'src/app/components/lists/list.component';
import { UsersService } from 'src/app/services/user.services';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { Config } from 'src/app/config/config';

@Component({
  templateUrl: '../../../components/common/templates/app-list-template.html',
  styleUrls: ['../../../styles/main.scss']
})
export class ListUsersComponent implements ListComponentInterface {
  actions: ListActions[];
  activateSeeker: boolean;
  activateSelectParent: boolean;
  childrensKey?: string;
  columns: any;
  current: any;
  data: any;
  deleteAlertMessage: string;
  groupSelectOptions?: boolean;
  open: false;
  page: number;
  parent?: any;
  parentData?: any[];
  tableId: string;
  title: string;

  constructor(
    private userService: UsersService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.actions = [ListActions.create, ListActions.update, ListActions.delete];
    this.activateSelectParent = false;
    this.activateSeeker = false;
    this.columns = [
      'name',
      'lastname',
      'username',
      'email',
      'phone',
      'position',
      'active',
      'createdAt',
    ];
    this.deleteAlertMessage = 'sure_delete_user';
    this.open = false;
    this.tableId = 'uuid';
    this.title = 'users';
    this.getUsers();
  }

  getUsers(page?, take = 10, keyword?: string) {
    // tslint:disable-next-line: no-unused-expression
    page && (this.page = page);
    this.userService.getAll(this.page, take, keyword).subscribe((data: any) => {
      this.data = data.data.data.map(user => {
        return { ...user, createdAt: moment(user.createdAt).format('DD/MM/YYYY') };
      });
      this.current = data.data;
    });
  }

  create() {
    this.update(null);
  }

  delete?: (event: any) => any;

  pageCurrent(page) {
    this.getUsers(page);
  }
  parentSelected?: (id: string | number) => any;

  search(keyword: string) {
    this.getUsers(null, 10, keyword);
  }

  async update(id) {
    const route = this.location.path().replace('/', '').split('?').shift();
    localStorage.setItem(Config.returnUrl, route);
    this.router.navigate([Config.router.createUser], { queryParams: { id } });
  }

  view?: (id: string | number) => any;

}
