import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface ListComponentInterface {
  // properties
  actions: Array<ListActions>;
  activateSeeker: boolean;
  activateSelectParent: boolean;
  childrensKey?: string;
  columns: any;
  current: any;
  data: any;
  deleteAlertMessage: string;
  groupSelectOptions?: boolean;
  parent?: any;
  parentData?: Array<any>;
  tableId: string;
  title: string;

  // methods
  create?: () => any;
  delete?: (event: any) => any;
  pageCurrent?: (page: number) => any;
  parentSelected?: (id: number | string) => any;
  search?: (keyword: string) => any;
  update?: (id: number | string) => any;
  view?: (id: number | string) => any;

}

export enum ListActions {
  create = 'create',
  delete = 'delete',
  update = 'update',
  view = 'view',
}

@Component({
  selector: 'app-list-component',
  templateUrl: './list.component.html',
  styleUrls: ['../../styles/main.scss']
})
export class ListComponent implements ListComponentInterface {
  public keyword: string;
  @Input() actions: Array<ListActions>;
  @Input() activateSeeker: boolean;
  @Input() activateSelectParent: boolean;
  @Input() childrensKey: string;
  @Input() columns: any;
  @Input() current: any;
  @Input() data: any;
  @Input() deleteAlertMessage: string;
  @Input() groupSelectOptions: boolean = false;
  @Input() parent: any = '';
  @Input() parentData: Array<any>;
  @Input() tableId: string;
  @Input() title: string;
  @Output() createEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() page = new EventEmitter();
  @Output() parentSelectedEvent = new EventEmitter();
  @Output() searchEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();
  @Output() viewEvent = new EventEmitter();

  create() {
    this.createEvent.emit();
  }
  type(value) {
    if (typeof value === 'boolean') {
      return true;
    } else {
      return isNaN(Number(value));
    }

  }
  delete(event) {
    this.deleteEvent.emit(event);
  }

  update(id: number | string) {
    this.updateEvent.emit(id);
  }

  view(id: number | string) {
    this.viewEvent.emit(id);
  }

  pageCurrent(page: number) {
    this.page.emit(page);
  }

  parentSelected(id: number | string) {
    this.parentSelectedEvent.emit(id);
  }

  search(keyword: string | number) {
    this.searchEvent.emit(keyword);
  }

}
