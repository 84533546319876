import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { QueryBuilder } from './QueryBuilder';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService {

  constructor(
    private httpClient: HttpClient
  ) { }

  get(): Observable<any> {
    return this.httpClient.get(Config.api + 'pay-mode');
  }



}
