import { Category } from '../../../../model/Category';
import { CategoryService } from 'src/app/services/categories.services';
import { Component } from '@angular/core';
import { Config } from '../../../../config/config';
import { ListComponentInterface, ListActions } from 'src/app/components/lists/list.component';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: '../../../../components/common/templates/app-list-template.html',
  styleUrls: ['../../../../styles/main.scss']
})
export class ListCategoriesComponent implements ListComponentInterface {
  actions: ListActions[];
  activateSeeker: boolean;
  activateSelectParent: boolean;
  columns: string[];
  current: any;
  data: Array<Category>;
  deleteAlertMessage: string;
  id: any = null;
  open: boolean;
  page: number;
  parentData?: Array<any>;
  parent?: string;
  tableId: string;
  title: string;

  constructor(
    private categoryService: CategoryService,
    private location: Location,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.actions = [ ListActions.create, ListActions.update, ListActions.delete ];
    this.activateSelectParent = false;
    this.activateSeeker = false;
    this.columns = ['name', 'seo'];
    this.deleteAlertMessage = 'sure_delete_category';
    this.open = false;
    this.tableId = 'id';
    this.title = 'categories';
    this.getCategories();
  }

  getCategories(page?) {
    // tslint:disable-next-line: no-unused-expression
    page && (this.page = page);
    this.categoryService.get(page).subscribe((data) => {
      this.data = data.data;
      this.current = data;
    });
  }

  pageCurrent(page) {
    this.getCategories(page);
  }

  create() {
    this.update(null);
  }

  delete(obj: {id: any, confirmed: boolean}) {
    this.id = (obj.id) ? obj.id : this.id;
    if (obj.confirmed) {
      this.categoryService.update({ isDeleted: true, uuid: this.id }, this.id).subscribe((put) => {
        this.getCategories(this.page);
        this.toastr.success(this.translate.instant('deleted_category'));
      });
    } else {
      this.open = !this.open;
    }
  }

  async update(id) {
    const route = this.location.path().replace('/', '').split('?').shift();
    await localStorage.setItem(Config.returnUrl, route);
    this.router.navigate([Config.router.createCategory], { queryParams: { id } });
  }

  view(id: string | number) { }

  search(keyword: string) { }
}
