import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import { Router, NavigationEnd, Event, ActivatedRoute } from '@angular/router';
import { Config } from '../../../app/config/config';
import { Location } from '@angular/common';
import { CategoryService } from 'src/app/services/categories.services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Observable, Subject,  } from 'rxjs';
import { ShoppingCartService } from 'src/app/services/shoppingCart.service';
import { GlobalState } from 'src/app/services/events.service';
import { AuthService } from 'src/app/services/auth.services';
import { ProductService } from '../../services/product.services';
import { Socket } from 'ng-socket-io';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['../../styles/main.scss'],
  providers: [ShoppingCartService]
})
export class BaseComponent implements OnInit, OnDestroy  {
  categories: any;
  openCategories: boolean;
  indexCategories: any;
  currentLang;
  year: any;
  customContainer: any;
  classCustom: any;
  carBanner: boolean = false;
  carProducts: any;
  total: number;
  iva: number;
  tasa: number;
  subtotal: number;
  urlBack: string;
  subscribes: any;
  isLogin: any;
  config: any = Config;
  routeMain: any;
  payload: any;
  searchText: string;
  keyword = 'name';
  data = [];
  rol: any;
  userId: any;
  @ViewChild('audio') private audio: ElementRef;
  constructor(private categoryService: CategoryService,
              private translateService: TranslateService,
              public router: Router,
              private shoppingCartService: ShoppingCartService,
              public _state: GlobalState,
              private authService: AuthService,
              private routere: ActivatedRoute,
              public location: Location,
              public productService: ProductService,
              private toastr: ToastrService,
              private socket: Socket) {
    this.year = moment().year();
    this.tasa = 12;
    this.urlBack = Config.api + 'attachments/';
    this.infoToke();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.infoToke();
      }
    });
    this.searchText = this.routere.snapshot.queryParamMap.get('search');
    this.customContainer = false;
    this.subscribes = [];
    const route = this.location.path().replace('/', '').split('?').shift();
    this.validateRoute(route);
  }
  infoToke() {
    this.isLogin = this.authService.islogin();
    if (this.authService.islogin()) {
      this.payload = this.authService.payload();
      this.userId = this.authService.payload().uuid;
    }
  }
  logout() {
    this.authService.logOut();
  }
  ngOnInit() {
    this._state.replaySubjectSubscribe('login').subscribe((data) => {
      this.infoToke();
    });
    this.socket.on(`newChat:${this.userId}`, (data) => {
      const route = this.location.path().replace('/', '').split('?').shift();
      if (Config.router.chat !== route && data.user.uuid !== this.userId) {
        this.audio.nativeElement.play();
        setTimeout(() => {
          this.audio.nativeElement.pause();
          this.audio.nativeElement.currentTime = 0;
        }, 2000);
      }
    });
    this.currentLang = this.translateService.currentLang;
    const translateSubs = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    });
    this.subscribes.push(translateSubs);
    const categorySubs = this.categoryService.get().subscribe((data) => {
      this.categories = data.data;
      this.categories.map((category, index) => {
        this.categoryService.getSubcategoriesByCategory(category.id).subscribe((sub) => {
          category.subcategories = sub.data.subCategories;
        });
      });
    });
    this.subscribes.push(categorySubs);
    const routerSubs = this.router.events.subscribe((evt: any) => {
      if (evt instanceof NavigationEnd) {
        const route = evt.url.replace('/', '').split('?').shift();
        this.validateRoute(route ? route : false);
      }
      window.scrollTo(0, 0);
    });
    this.subscribes.push(routerSubs);
    this._state.replaySubjectSubscribe('car').subscribe((data) => {
      this.openCar();
    });
  }
  navigateGo(route) {
    this.router.navigate([route]);
  }
  ngOnDestroy() {
    this.subscribes.map((element) => {
      element.unsubscribe();
    });
  }
  search(search) {
    this.router.navigate([Config.router.result], {queryParams: {search}});
    this._state.replaySubjectChanged('search', search);
  }
  selectCategory(id) {
    this.router.navigate([Config.router.result], {queryParams: {category: id, search: this.searchText}});
    this._state.replaySubjectChanged('category', id);
  }

  selectEvent(search) {
    this._state.replaySubjectChanged('search', search);
  }

  onChangeSearch(val: string) {
    this.productService.search(val).subscribe((data) => {
      this.data = data.data[1];
    });
  }

  selectSubCategory(id) {
    if (this.location.path().replace('/', '').split('?').shift() === Config.router.result) {
      const urlTree = this.router.createUrlTree([], {
        queryParams: {Subcategory: id},
        queryParamsHandling: 'merge',
        preserveFragment: true });
      this.router.navigateByUrl(urlTree);
      this.openCategories= false;
    } else {
      this.router.navigate([Config.router.result], {queryParams: {Subcategory: id}});
      this.openCategories= false;
    }
    this._state.replaySubjectChanged('Subcategory', id);
  }
  eliminarItemCarro(uuid) {
    this.shoppingCartService.removeOne(uuid)
      .subscribe(res => {
        this.getShopping();
      });
  }
  validateRoute(route) {
    if (route === Config.router.detailsProduct) {
      this.classCustom = Config.router.detailsProduct.replace('/', '-');
      this.customContainer = true;
    } else {
      this.customContainer = false;
    }
  }
  open() {
    this.openCategories = !this.openCategories;
  }
  getShopping() {
    const shoppingSubs = this.shoppingCartService.getShoppingCart().subscribe((data) => {
      this.carProducts = data.data.shoppingCarts;
      this.calculateFullAmount();
    });
    this.subscribes.push(shoppingSubs);
  }
  async calculateFullAmount() {
    this.total = 0;
    await this.carProducts.forEach(element => {
      this.iva = ((((element.product.salePrice) ? element.product.salePrice : 1)  * this.tasa) / 100);
      this.subtotal =  this.total + element.quantity * element.product.salePrice;
      this.total =  ((element.product.salePrice * this.tasa) / 100) + (this.total + element.quantity * element.product.salePrice);
    });
  }
  openCar() {
    if (this.authService.islogin()) {
      this.getShopping();
      this.carBanner = !this.carBanner;
    } else {
      this.router.navigate([Config.router.login]);
    }
  }
  login() {
    this.router.navigate([Config.router.login]);
  }
  processOrder() {
    const carro = { paymentType: 'usd'};
    this.shoppingCartService.process(carro).subscribe(() => {
      this.getShopping();
      this.toastr.success('Orden creada con exito');
    });
  }
  changeLang(lang) {
    localStorage.setItem(Config.lang, lang);
    this.translateService.use(lang);
  }
  selectCategorie(index) {
    this.indexCategories = index;
  }

 }
