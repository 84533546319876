import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private httpClient: HttpClient
  ) { }

  get(query?): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.categories);
  }
  getByOne(uuid, query?): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.categories + `/${uuid}` + '/parent_categories');
  }
  save(body): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.categories + `/add`, body);
  }
  update(body, id): Observable<any> {
    return this.httpClient.put(Config.api + Config.endpoints.categories + `/${id}`, body);
  }
  getSubcategoriesByCategory(id, query?): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.categories + '/' + id + '/subcategories');
  }
  getAllSubcategories(): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.subcategories);
  }

}
