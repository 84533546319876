import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  sub: any;
  constructor(
    private httpClient: HttpClient,
    public router: Router,
    public location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) { }

  login(data): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.login, data);
  }
  sendCode(data): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.activate, data);
  }
  newCode(data): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.new_code, data);
  }
  payload() {
    return (localStorage.getItem(Config.token)) ? helper.decodeToken(localStorage.getItem(Config.token)) : null;
  }
  islogin() {
    return (localStorage.getItem(Config.token)) ? true : false;
  }
  storageToken(data) {
    return localStorage.setItem(Config.token, data);
  }
  async logOut() {
    await localStorage.removeItem(Config.token);
    const route = this.location.path().replace('/', '').split('?').shift();
    await localStorage.setItem(Config.returnUrl, route);
    if (Config.router.login !== route) {
      this.sub = this.route.queryParams.subscribe(async (params) => {
        if (Object.keys(params).length) {
          await localStorage.setItem(Config.paramsQuery, JSON.stringify(params));
        }
        await localStorage.setItem(Config.returnUrl, route);
      });
      this.sub.unsubscribe();
      this.router.navigate([Config.router.login]);
    }
  }


}
