import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from '../app/services/auth.services';
import { Config } from '../app/config/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./styles/main.scss']
})
export class AppComponent {
  title = 'app';
  routers = Config.router;
  constructor(translate: TranslateService, router: Router, authService: AuthService) {
    // available languages
    translate.addLangs(['en', 'es']);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (localStorage.getItem(Config.lang)) {
      translate.use(localStorage.getItem(Config.lang));
    } else {
      translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
    }
  }
}
