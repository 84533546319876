import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../../../../services/categories.services';
import { Component, OnInit } from '@angular/core';
import { CreationComponentInterface } from '../../../../components/componentCreation/creation.component';
import { Location } from '@angular/common';
import { Tag } from 'src/app/model/Tag';
import { TagService } from 'src/app/services/tag.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: '../../../../components/common/templates/create.component.html',
  styleUrls: ['../../../../styles/main.scss'],
  providers: [CategoryService]
})
export class CreateTagComponent implements CreationComponentInterface {
  buttonPosition: any;
  columns: any;
  data: any;
  formantView: number;
  id: string | number;
  instance: any;
  rules: any;
  title: string;
  validateInputs: boolean;

  constructor(
    private categoryService: CategoryService,
    private tagService: TagService,
    private location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.columns = [
      {
        name: 'categoryId',
        type: 'select',
        data: [],
        groupSelectOptions: true,
        childrensKey: 'subCategories'
      },
      {
        name: 'name',
        type: 'input',
      },
    ];
    this.getCategories();
    this.id = this.route.snapshot.queryParamMap.get('id');
    if (this.id) {
      this.title = 'update_tag';
      this.tagService.getOne(this.id).subscribe((data: { data }) => {
        this.instance = {...data.data, categoryId: data.data.category.id};
      });
    } else {
      this.title = 'create_tag';
    }
  }

  getCategories() {
    this.categoryService.getAllSubcategories().subscribe(data => {
      this.columns[0].data = data.data.map(category => {
        const subCategories = category.subCategories.map(subcategory => {
          return { value: subcategory.id, name: subcategory.name };
        });
        return {...category, subCategories };
      });
    });

  }

  save(data: Partial<Tag>) {
    if (!this.id) {
      this.tagService.save(data).subscribe(unit => {
        this.toastr.success(this.translate.instant('tag_created'));
        this.location.back();
      }, (error) => this.toastr.error(error.message));
    } else {
      delete data.category;
      this.tagService.update(data, this.id).subscribe(unit => {
        this.toastr.success(this.translate.instant('updated_tag'));
        this.location.back();
      }, (error) => this.toastr.error(error.message));
    }
  }
}
