import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { QueryBuilder } from './QueryBuilder';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getAll(categoryId?: number, page = 1, take = 10, keyword?: string): Observable<any> {
    let objQuery: any = { page, take };
    // tslint:disable-next-line: no-unused-expression
    keyword && (objQuery = { ...objQuery, keyword });
    // tslint:disable-next-line: no-unused-expression
    categoryId && (objQuery = { ...objQuery, categoryId });

    const params: HttpParams = QueryBuilder.queryToHttpParams(QueryBuilder.verifyObject(objQuery));
    return this.httpClient.get(Config.api + Config.endpoints.tags, { params });
  }

  getOne(id: number | string): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.tags + `/${id}`);
  }

  save(body): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.tags , body);
  }

  update(body, id): Observable<any> {
    return this.httpClient.put(Config.api + Config.endpoints.tags + `/${id}`, body);
  }

  delete(id: number): Observable<any> {
    return this.httpClient.delete(Config.api + Config.endpoints.tags + `/${id}`);
  }

}
