import { Injectable, OnDestroy } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/internal/operators';
import { Config } from '../config/config';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
    count: number = 0;
    params: any;
    sub: any;
    constructor(
        private router: Router,
        public location: Location,
        private route: ActivatedRoute,
        private toastr: ToastrService,
    ) { }


    /**
     * intercept all XHR request
     * @param request
     * @param next
     * @returns {Observable<A>}
     */
    ngOnDestroy() {
      this.sub.unsubscribe();
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = localStorage.getItem(Config.token);
        let headers: HttpHeaders;

        if (request.headers.has(InterceptorSkipHeader)) {
            headers = request.headers.delete(InterceptorSkipHeader);
        } else {
            headers = request.headers.delete(InterceptorSkipHeader);
            if (token) {
                headers = headers.append('Authorization', `Bearer ${token}`)
            }
        }

        request = request.clone({ headers });

        /**
         * continues request execution
         */
        return next.handle(request).pipe(catchError((error, caught): any => {
            // intercept the respons error and displace it to the console
            this.handleAuthError(error);
        }) as any);
    }


    /**
     * manage errors
     * @param err
     * @returns {any}
     */
    public handleAuthError(err: HttpErrorResponse) {
        const route = this.location.path().replace('/', '').split('?').shift();
        if (err instanceof HttpErrorResponse && err.status === 401 && Config.router.login !== route) {
            localStorage.removeItem(Config.token);
            if (Config.router.login !== route) {
              this.sub = this.route.queryParams.subscribe(async (params) => {
                if (Object.keys(params).length) {
                  await localStorage.setItem(Config.paramsQuery, JSON.stringify(params));
                }
                await localStorage.setItem(Config.returnUrl, route);
                this.toastr.error('Session expired, enter again');
              });
              this.sub.unsubscribe();
              this.router.navigate([Config.router.login]);
            }
        }
        throw err;
    }
}
