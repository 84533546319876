// libraries
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ValidationPassword } from './services/validations.services';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FileUploadModule } from 'ng2-file-upload';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { PopoverModule } from 'ngx-bootstrap/popover';
// componest
import {AppComponent} from './app.component';
import {LoginPageComponent} from './login-page/login-page.component';
import { RegisterComponent } from './register/register.component';
import { CreateProductComponent } from './views/products/create/createProduct.component';
import { ListProductComponent } from './views/products/list/listProduct.component';

// services, config, pipe
import {NeedAuthGuard} from './auth.guard';
import { RoutersMain } from './config/routers';
import { AuthInterceptor } from './services/auth.interceptor';
import { PaginateComponent } from './components/paginate/paginate.component';
import { ListComponent } from './components/lists/list.component';
import { AlertModalComponent } from './components/alertModal/alertModal.component';
import { CreationComponent } from './components/componentCreation/creation.component';
import { CreateCategoryComponent } from './views/admin/categories/create/createCategory.component';
import { ListCategoriesComponent } from './views/admin/categories/list/listCategories.component';
import { ListUnitsComponent } from './views/admin/units/list/listUnits.component';
import { BaseComponent } from './views/base/base.component';
import { HomeComponent } from './views/products/home/home.component';
import { CardComponent } from './components/card/card.component';
import { CreateUnitComponent } from './views/admin/units/create/createUnit.component';
import { ListCountriesComponent } from './views/admin/countries/list/listCountries.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CreateCountryComponent } from './views/admin/countries/create/createCountry.component';
import { DetailsProductComponent } from './views/products/details/details.component';
import { GlobalState } from './services/events.service';
import { ListCitiesComponent } from './views/admin/cities/list/listCities.component';
import { CreateCityComponent } from './views/admin/cities/create/createCity.component';
import { ListTagsComponent } from './views/admin/tags/list/listTags.component';
import { CreateTagComponent } from './views/admin/tags/create/createTag.component';
import { ListOrdersComponent } from './views/orders/list/listOrders.component';
import { ChatComponent } from './views/chat/chat.component';
import { ResultComponent } from './views/products/result/result.component';
import { ProfileComponent } from './views/profile/profile.component';
import { ListUsersComponent } from './views/users/list/userList.component';
import { CreateUserComponent } from './views/users/create/createUser.component';
import { UsersService } from './services/user.services';
import { FavoriteComponent } from './views/products/favorite/favorite.component';
import { OrderViewComponent } from './views/orders/view/orderView.component';
import { SocketIoModule, SocketIoConfig } from 'ng-socket-io';
import { Config } from '../app/config/config';
// configuration translate
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
const config: SocketIoConfig = { url: Config.api, options: {} };

@NgModule({
  declarations: [
    AlertModalComponent,
    AppComponent,
    BaseComponent,
    CreateCategoryComponent,
    CreateCityComponent,
    CreateCountryComponent,
    CreateProductComponent,
    CreateTagComponent,
    CreateUnitComponent,
    CreationComponent,
    ListCategoriesComponent,
    ListCitiesComponent,
    ListComponent,
    ListCountriesComponent,
    ListProductComponent,
    ListTagsComponent,
    ListUnitsComponent,
    LoginPageComponent,
    PaginateComponent,
    ListComponent,
    AlertModalComponent,
    CreationComponent,
    BaseComponent,
    HomeComponent,
    CardComponent,
    RegisterComponent,
    DetailsProductComponent,
    ResultComponent,
    ProfileComponent,
    ListOrdersComponent,
    ChatComponent,
    ListUsersComponent,
    CreateUserComponent,
    FavoriteComponent,
    OrderViewComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      tapToDismiss: true,
      maxOpened: 1
    }),
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgxDropzoneModule,
    FileUploadModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(RoutersMain.appRoutes),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    AutocompleteLibModule,
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    SocketIoModule.forRoot(config),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    TranslateService,
    NeedAuthGuard,
    ValidationPassword,
    GlobalState,
    UsersService,
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent]
})
export class AppModule {
}
