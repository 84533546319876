import { Component } from '@angular/core';
import { Config } from '../../../../config/config';
import { ListComponentInterface, ListActions } from '../../../../components/lists/list.component';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Unit } from '../../../../model/Unit';
import { UnitService } from '../../../../services/units.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: '../../../../components/common/templates/app-list-template.html',
  styleUrls: ['../../../../styles/main.scss']
})
export class ListUnitsComponent implements ListComponentInterface {
  actions: ListActions[];
  activateSeeker: boolean;
  activateSelectParent: boolean;
  columns: string[];
  current: any;
  data: Array<Unit>;
  deleteAlertMessage: string;
  id: any = null;
  open: boolean;
  page: number;
  parentData?: Array<any>;
  parent?: string;
  tableId: string;
  title: string;

  constructor(
    private location: Location,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private unitsService: UnitService,
  ) {
    this.actions = [ ListActions.create, ListActions.update, ListActions.delete ];
    this.activateSeeker = false;
    this.activateSelectParent = false;
    this.columns = ['name', 'symbol'];
    this.deleteAlertMessage = 'sure_delete_unit';
    this.open = false;
    this.tableId = 'id';
    this.title = 'units';
    this.getUnits();
  }

  getUnits(page?) {
    // tslint:disable-next-line: no-unused-expression
    page && (this.page = page);
    this.unitsService.getAll().subscribe((data) => {
      this.data = data.data;
    });
  }

  pageCurrent(page) {
    this.getUnits(page);
  }

  async update(id) {
    const route = this.location.path().replace('/', '').split('?').shift();
    await localStorage.setItem(Config.returnUrl, route);
    this.router.navigate([Config.router.createUnit], { queryParams: { id } });
  }

  create() {
    this.update(null);
  }

  delete(obj: {id: any, confirmed: boolean}) {
    this.id = (obj.id) ? obj.id : this.id;
    if (obj.confirmed) {
      this.unitsService.delete(this.id).subscribe((put) => {
        this.getUnits(this.page);
        this.toastr.success(this.translate.instant('deleted_unit'));
      });
    } else {
      this.open = !this.open;
    }
  }

  view(id: string | number) { }

  search(keyword: string) { }

}
