import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/config/config';
import { CreationComponentInterface } from 'src/app/components/componentCreation/creation.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Unit } from 'src/app/model/Unit';
import { UnitService } from 'src/app/services/units.service';

@Component({
  templateUrl: '../../../../components/common/templates/create.component.html',
  styleUrls: ['../../../../styles/main.scss'],
  providers: [UnitService]
})
export class CreateUnitComponent implements OnInit, CreationComponentInterface {
  buttonPosition: any;
  columns: any;
  data: any;
  formantView: number;
  id: any;
  instance: Partial<Unit>;
  rules: any;
  title: string;
  validateInputs: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private unitService: UnitService,
  ) {
    this.id = this.route.snapshot.queryParamMap.get('id');
    if (this.id) {
      this.title = 'update_unit';
      this.unitService.getOne(this.id).subscribe((data) => {
        this.instance = data.data;
      });
    } else {
      this.title = 'create_unit';
    }
  }

  async ngOnInit() {
    this.columns = [
      {
        name: 'name',
        type: 'input',
      },
      {
        name: 'symbol',
        type: 'input',
      },
    ];

  }

  save(data) {
    if (!this.id) {
      this.unitService.save(data).subscribe((unit) => {
        this.instance = data.data;
        this.toastr.success(this.translate.instant('created_country'));
      }, (error) => this.toastr.error(error.message));
    } else {
      this.unitService.update(data, this.id).subscribe((unit) => {
        this.instance = data.data;
        this.toastr.success(this.translate.instant('updated_country'));
      }, (error) => this.toastr.error(error.message));
    }
    this.router.navigate([Config.router.listUnits]);
  }
}
