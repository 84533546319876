import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { User } from '../model/UserModel';
import { Company } from '../model/CompanyModel';
import { Category } from '../model/Category';
import { ValidationPassword } from '../services/validations.services';
import { UsersService } from '../services/user.services';
import { CompanyService } from '../services/company.service';
import { AuthService } from '../services/auth.services';
import { CountryService } from '../services/country';
import { CategoryService } from '../services/categories.services';
import { PlansService } from '../services/plans.services';
import { Config } from '../config/config';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { QueryBuilder} from '../services/QueryBuilder';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../styles/main.scss'],
  providers: [UsersService, CompanyService, AuthService, CategoryService, CountryService, PlansService]
})

export class RegisterComponent implements OnInit, OnDestroy {
  wirzadNumber: any;
  selectedWirzad: number;
  user: User;
  selectSupplier: any;
  view: any;
  userRegister: NgForm;
  companyRegister: NgForm;
  company: Company;
  planSelected: number;
  zxcvbnPassword: number;
  plans: any;
  capitalLetters: boolean;
  passwordNumber: boolean;
  passwordSimbol: boolean;
  lowerCasePass: boolean;
  userData: User;
  categories: Category;
  countries: any;
  cities: any;
  routers = Config.router;
  companySucess: any;
  planSucess: any;
  imgLogin: number;
  interval: any;
  uuidUser: any;
  classImgLogin: any;
  constructor(private validationPass: ValidationPassword,
              private usersService: UsersService,
              private companyService: CompanyService,
              private authService: AuthService,
              private categoryService: CategoryService,
              private countryService: CountryService,
              private toastr: ToastrService,
              private plansService: PlansService,
              private router: Router,
              private route: ActivatedRoute) {
    this.selectedWirzad = 0;
    this.capitalLetters = false;
    this.passwordNumber = false;
    this.passwordSimbol = false;
    this.imgLogin = Math.floor(Math.random() * (6 - 1)) + 1;
    this.classImgLogin = true;
    this.interval = setInterval(() => {
      this.classImgLogin = false;
      setTimeout(async () => {
        this.imgLogin = await Math.floor(Math.random() * 5) + 1;
        this.classImgLogin = true;
      }, 450);
    }, 10000);
    this.wirzadNumber = [
      {name: 'user_data', active: true},
      {name: 'enterprise_data', active: false},
      {name: 'plans', active: false},
    ];
  }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.get('selectedWizard')) {
      this.activeWirzad(this.route.snapshot.queryParamMap.get('selectedWizard'));
    }
    this.uuidUser = (this.route.snapshot.queryParamMap.get('uuid')) ?
    this.route.snapshot.queryParamMap.get('uuid') : '';
    this.plansService.get().subscribe((data) => {
      this.plans = data.data;
    });
    this.categoryService.get().subscribe((data) => {
      this.categories = data.data;
    });
    this.countryService.getCountries(null, 1000).subscribe((data) => {
      this.countries = data.data.data;
    });
    this.user = new User();
    this.company = new Company();
  }
  navigateGo(route) {
    this.router.navigate([route]);
  }
  ngOnDestroy() {
    clearInterval(this.interval);
  }
  getCities(id) {
    this.countryService.getCities(id, null, 1000).subscribe((data) => {
      this.cities = data.data.data;
    });
  }
  validatePassword(string, regExp) {
    return string.match(regExp);
  }
  uppercase(str) {
    return (str) ? str.toLowerCase() : str;
  }
  selectType(select) {
    this.selectSupplier = select;
  }
  queryRoute(obj) {
    const urlTree = this.router.createUrlTree([], {
      queryParams: obj,
      queryParamsHandling: 'merge',
      preserveFragment: true });

    this.router.navigateByUrl(urlTree);
  }
  activeWirzad(i) {
    this.selectedWirzad = Number(i);
    this.queryRoute(QueryBuilder.verifyObject({ uuid: this.uuidUser, selectedWizard: i}));
    this.wirzadNumber.map((obj, index) => {
      if (index == i) {
        obj.active = true;
      } else {
        obj.active = false;
      }
    });
  }
  updateCompany(uuid) {
    const plan = {planId: uuid};
    this.companyService.update(plan, this.companySucess.uuid).subscribe((data) => {
      this.planSucess = data;
      this.activeWirzad(this.selectedWirzad + 1);
      this.toastr.success('Plan agregado');
    }, (error) => {
      this.toastr.error('Error seleccionando plan' + error.error.message);
    });
  }
  createCompany(value) {
    value.value.userUuid = this.uuidUser;
    value.value.isSupplier = (this.selectSupplier === 1) ? 0 : 1;
    this.companyService.create(value.value).subscribe((data) => {
      this.companySucess = data.data;
      this.activeWirzad(this.selectedWirzad + 1);
      this.toastr.success('Empresa creada');
    }, (error) => {
      this.toastr.error('Error creando empresa' + error.error.message);
    });
  }
  CreateUser(value) {
    this.usersService.register(value.value).subscribe((data: any) => {
      this.userData = data.data.user;
      this.uuidUser = this.userData.uuid;
      this.activeWirzad(this.selectedWirzad + 1);
      this.toastr.success('Usuario creado');
    }, (error) => {
      this.toastr.error('Error creando usuario' + error.error.message);
    });
  }
  selectPlan(plan) {
    this.planSelected = plan;
  }
  validationPassword(password) {
    this.validationPass.validationPassword(password).then((validations: any) => {
      this.zxcvbnPassword = validations.passwordValidation;
      this.capitalLetters = validations.capitalLetters;
      this.lowerCasePass = validations.LowerCase;
      this.passwordNumber = validations.passwordNumber;
      this.passwordSimbol = validations.passwordSimbol;
    })
  }

}
