import {Component, OnInit, Input, Output, ViewChild, ElementRef} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Product } from '../../../model/Product';
import { ProductService } from '../../../services/product.services';
import { Config } from '../../../config/config';
import { Router } from '@angular/router';
import { ShoppingCartService } from 'src/app/services/shoppingCart.service';
import { GlobalState } from 'src/app/services/events.service';
import { ToastrService } from 'ngx-toastr';
import { FavoriteService } from 'src/app/services/favorite.services';
import { AuthService } from 'src/app/services/auth.services';
import { Socket } from 'ng-socket-io';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../../../styles/main.scss'],
  providers: [ShoppingCartService, FavoriteService]
})
export class HomeComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('template') elementView: ElementRef;
  products: Array<Product>;
  Config: any = Config;
  oneProduct: Product;
  imgProduct: string;
  quantity: number = 1;
  urlBack: string;
  config = Config;
  userId: any;
  @ViewChild('audio') private audio: ElementRef;
  constructor(
    private modalService: BsModalService,
    private productService: ProductService,
    private router: Router,
    private shoppingCartService: ShoppingCartService,
    public _state: GlobalState,
    private toastr: ToastrService,
    private favoriteService: FavoriteService,
    private authService: AuthService,
    private socket: Socket) {
    this.urlBack = Config.attachments;
    this.product();
  }
  ngOnInit() {
  }
  operationQuantity(operation) {
    if (operation) {
      this.quantity = Number(this.quantity) + 1;
    } else {
      this.quantity = Number(this.quantity) - 1;
    }
  }
  product() {
    this.productService.getProducts(1, 20).subscribe((data) => {
      this.products = data.data.products;
    });
  }
  detailsProduct(uuid) {
    this.router.navigate([Config.router.detailsProduct], { queryParams: { uuid } });
  }
  addFavorite(product) {
    console.log(product);

    this.favoriteService.add(product).subscribe((data) => {
      this.toastr.success('Agregado a lista de deseo', '');
    });
  }
  open(uuid) {
    this.productService.getProduct(uuid).subscribe((data) => {
      this.oneProduct = data.data;
      if (this.oneProduct.productAttachments.length) {
        this.imgProduct = this.oneProduct.productAttachments[0].url;
      }
      this.modalRef = this.modalService.show(
        this.elementView,
        Object.assign({}, { class: 'gray modal-lg' })
      );
    });

  }
  navigateGo(route) {
    this.router.navigate([route]);
  }
  addCar(uuid, quantity) {
    this.shoppingCartService.addProduct({ uuid, quantity: Number(quantity)})
      .subscribe(res => {
        this._state.replaySubjectChanged('car', res);
        this.modalRef.hide();
        this.toastr.success('Agregado al carro', '');
    });
  }
}
