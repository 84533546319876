import {Component, OnInit, TemplateRef, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {ApiService} from '../api.service';
import {CustomerService} from '../customer.service';
import {Router, ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import { AuthService } from '../services/auth.services';
import { Config } from '../config/config';
import { User } from '../model/UserModel';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GlobalState } from '../services/events.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['../styles/main.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {

  email: string;
  password: string;
  is_register: boolean;
  imgLogin: number;
  user: User;
  loginForm: NgForm;
  routers = Config.router;
  returnUrl: any;
  paramsRoute: any;
  modalRef: BsModalRef;
  code: any;
  interval: any;
  @ViewChild('template') elementView: ElementRef;
  @ViewChild('template2') elementView2: ElementRef;
  classImgLogin: boolean;
  constructor(private api: ApiService, private customer: CustomerService, private router: Router, private authService: AuthService,
              private route: ActivatedRoute, private toastr: ToastrService, private modalService: BsModalService,public _state: GlobalState) {
    this.is_register = false;
    this.user = new User();
    this.returnUrl = Config.router.createProduct;
    if (localStorage.getItem('returnUrl')) {
      this.returnUrl = localStorage.getItem('returnUrl');
    }
  }
  sendCode(code, email) {
    const obj = {code, email};
    this.authService.sendCode(obj).subscribe((data) => {
      this.modalRef.hide();
      this.toastr.success('Usuario activado');
    }, () => {
      this.toastr.error('Error vuelva a intentar');
    });
  }
  home() {
    this.router.navigate([Config.router.main]);
  }
  newCode(email) {
    this.authService.newCode({email}).subscribe((data) => {
      this.modalRef.hide();
      this.toastr.success('nuevo codigo enviado');
      this.modalRef = this.modalService.show(this.elementView);
    }, (err) => {
      this.toastr.error('Error vuelva a intentar');
    });
  }
  openModal() {
    this.modalRef.hide();
    this.modalRef = this.modalService.show(this.elementView2);
  }
  ngOnInit() {
    this.imgLogin = Math.floor(Math.random() * 5) + 1;
    this.classImgLogin = true;
    this.interval = setInterval(() => {
      this.classImgLogin = false;
      setTimeout(async () => {
        this.imgLogin = await Math.floor(Math.random() * 5) + 1;
        this.classImgLogin = true;
      }, 450);
    }, 10000);
    if (localStorage.getItem(Config.paramsQuery)) {
      this.paramsRoute = JSON.parse(localStorage.getItem(Config.paramsQuery));
    }
  }
  ngOnDestroy() {
    clearInterval(this.interval);
  }
  year() {
    return moment().year();
  }
  changeRegister() {
    this.is_register = !this.is_register;
  }
  navigateGo(route) {
    this.router.navigate([route]);
  }
  login(data) {
    this.authService.login(data.value).subscribe(async (data: any) => {
      await localStorage.setItem(Config.token, data.data.token);
      this.toastr.success('Bienvenido a goodpanda');
      const query = (localStorage.getItem(Config.paramsQuery)) ? { queryParams: this.paramsRoute} : {};
      if (localStorage.getItem(Config.token)) {
        this.router.navigate([this.returnUrl], query);
        localStorage.removeItem(Config.paramsQuery);
      }
      this._state.replaySubjectChanged('login', data);
    }, (err) => {
      this.toastr.error(err.error.message);
      if (err.status === 403) {
        this.modalRef = this.modalService.show(this.elementView);
      }
    });
  }
}
