import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ShoppingCartService } from 'src/app/services/shoppingCart.service';
import { GlobalState } from 'src/app/services/events.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['../../styles/main.scss']
})
export class CardComponent implements OnInit {
  @Input() img: string;
  @Input() title: string;
  @Input() price: any;
  @Input() id: any;
  @Input() product: any;
  @Output() clickEvent = new EventEmitter();
  @Output() clickEventComplete = new EventEmitter();
  @Output() carEvent = new EventEmitter();
  @Output() favEvent = new EventEmitter();
  constructor(
    private shoppingCartService: ShoppingCartService,
    public _state: GlobalState,
    private toastr: ToastrService) {

  }
  ngOnInit() {

  }
  select(id) {
    this.clickEvent.emit(id);
  }
  View(id) {
    this.clickEventComplete.emit(id);
  }
  favorite(product) {
    this.favEvent.emit(product);
  }
  addCar(uuid, quantity) {
    this.shoppingCartService.addProduct({ uuid, quantity: Number(quantity)})
      .subscribe(res => {
        this._state.replaySubjectChanged('car', res);
        this.toastr.success('Agregado al carro', '');
      });
  }
}
