import { Component, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { OrderService } from '../../../services/order.service';
import { ShippingsService } from '../../../services/shippings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Config } from 'src/app/config/config';
import { Shipping } from '../../../model/shipping';
import { Order } from '../../../model/order';
import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { map, find } from 'rxjs/operators';
import { PortsService } from 'src/app/services/port.service';

@Component({
  templateUrl: './orderView.component.html',
  styleUrls: ['../../../styles/main.scss']
})
export class OrderViewComponent implements OnDestroy {
  page: any;
  data: any;
  current: any;
  modalRef: BsModalRef;
  quantitySend: any;
  quantitytoSend: any;
  shipping: Shipping;
  shippingSelected: Shipping;
  newOrder: any;
  id: any;
  shippings: Array<Shipping>;
  moment = moment;
  weeks = Array.from({ length: 52 }, (v, k) => k + 1);
  ports: any;
  minDate: Date;
  maxDate: Date;
  bsValue = new Date();
  @ViewChild('template') elementView: ElementRef;
  constructor(
    private orderService: OrderService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private shippingsService: ShippingsService,
    private portsService: PortsService
  ) {
    this.minDate = this.bsValue;
    this.maxDate = this.bsValue;
    this.newOrder = new Order();
    this.newOrder.orderItems = [];
    this.shipping = new Shipping();
    this.id = this.route.snapshot.queryParamMap.get('id');
    this.getOrder(this.id);
    this.getShiping(this.id);
    this.portsService.get().subscribe((data) => {
      this.ports = data.data;
    });
  }
  setMaxDate(date: Date) {
    this.shipping.eta = date;
    this.maxDate = moment(date).add(1, 'days').toDate();
  }
  setETD(date: Date) {
    this.shipping.etd = date;
    this.shipping.week = this.calculateNumberOfWeeks(this.shipping.eta, this.shipping.etd);
  }
  getOrder(id) {
    this.orderService.getOneReceived(id).subscribe((data: any) => {
      this.data = data.data.order;
      if (this.data.orderItems) {
        this.data.orderItems.map((element) => {
          element.active = false;
        });
      }
    });
  }
  getShiping(id) {
    this.shippingsService.getShipping(id).subscribe((data) => {
      this.shippings = data.data;
    });
  }
  stringToNumber(string) {
    return Number(string);
  }
  validatequantity(quantity, quantitySend) {
    if (Number(quantity) < Number(this.quantitySend)) {
      this.quantitySend = Number(quantity);
    }
  }
  openModal() {
    this.modalRef = this.modalService.show(
      this.elementView,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }
  selectedShipping(shipping) {
    this.shippingSelected = shipping;
  }
  addItems(order) {
    this.data.orderItems.map((element) => {
      if (element.id === order.id) {
        element.active = !element.active;
      }
    });
    this.newOrder.orderItems = this.data.orderItems.filter((element) => element.active === true);
  }
  calculateNumberOfWeeks(d1, d2): number {
    const format = 'DD/MM/YYYY';
    if ( d1 === '' || d2 === '') {
        return 0;
    }
    if (moment(d1, format).isValid() && moment(d2, format).isValid()) {
        d1 = moment(d1, format);
        d2 = moment(d2, format);
        let w = (d1.diff(d2, 'days') / 7).toFixed(1);
        if (Number(w) < 1) {
            w = w;
        }
        return Math.ceil(Math.abs(Number(w)));
    }
}
  addShipping(shipping: Shipping, order) {
    this.newOrder = new Order(order);
    shipping.week = Number(shipping.week);
    shipping.portId = Number(shipping.portId);
    this.newOrder.orderItems = this.newOrder.orderItems.filter((element) => element.active === true);
    this.newOrder.orderItems.map(orderItems => {
      orderItems.quantitySent = Number(orderItems.quantitySent) + Number(orderItems.quantitytoSend);
    });
    shipping.order = this.newOrder;
    this.shippingsService.createShipping(shipping).subscribe((data) => {
      this.getOrder(this.id);
      this.getShiping(this.id);
      this.modalRef.hide();
    });
  }
  update(shipping: Shipping, order?) {
    this.shippingsService.updateShipping(shipping).subscribe((data) => {
      this.getOrder(this.id);
      this.getShiping(this.id);
    });
  }
  changeEtaAndEtd(date, type) {
    if (type === 'eta') {
      this.shippingSelected.eta = date;
    } else {
      this.shippingSelected.etd = date;
    }
    this.shippingsService.updateShipping(this.shippingSelected).subscribe((data) => {
      this.getOrder(this.id);
      this.getShiping(this.id);
    });
  }
  getOrders(page?) {
    // tslint:disable-next-line: no-unused-expression
    page && (this.page = page);
     this.orderService.getAllReceived(page).subscribe((data: any) => {
       this.data = data.data.orders.map((order: any) => {


         return {
           ...order,
           createdAt: moment(order.createdAt).format('DD/MM/YYYY'),
           items_quantity: order.orderItems.length,
           company: order.company.businessName
         };
       });
       this.current = data;
     });
 }
  ngOnDestroy(): void {
  }
}
