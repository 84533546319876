import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Config } from '../../../app/config/config';
import { Location } from '@angular/common';
import { CategoryService } from 'src/app/services/categories.services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Observable, Subject,  } from 'rxjs';
import { ShoppingCartService } from 'src/app/services/shoppingCart.service';
import { GlobalState } from 'src/app/services/events.service';
import { AuthService } from 'src/app/services/auth.services';
import { ChatService } from 'src/app/services/chat.service';
import { Socket } from 'ng-socket-io';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['../../styles/main.scss'],
  providers: [ShoppingCartService, ChatService]
})
export class ChatComponent implements OnInit, OnDestroy  {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('audio') private audio: ElementRef;
  messages: any;
  userId: any;
  message: any;
  modalRef: BsModalRef;
  chats: any;
  uuidChat: any;
  activeMsg: boolean;
  urlBack: any;
  uuidNew: any;
  chatSelected: any;
  @ViewChild('template') elementView: ElementRef;
  constructor(
    private modalService: BsModalService,
    private chatService: ChatService,
    private authService: AuthService,
    private socket: Socket,
    private router: Router,
    private route: ActivatedRoute, ) {
    this.userId = this.authService.payload().uuid;
    this.uuidNew = [];

  }
  async ngOnInit() {
    await this.getChats();
      if (this.route.snapshot.queryParamMap.get('uuidChat')) {
        this.uuidChat = this.route.snapshot.queryParamMap.get('uuidChat');
        await this.getMsg(null, this.uuidChat);
      }
    this.urlBack = Config.attachments;
    this.scrollToBottom();
    this.socket.on(`newMessage:${this.userId}`, (data) => {
      if (this.uuidChat ) {
        this.getMsg(null, this.uuidChat);
      }
      this.scrollToBottom();
      if (data.user.uuid !== this.userId && (!this.uuidChat) ? true : (this.uuidChat && data.chatRoom.uuid !== this.uuidChat)
          || document.hidden) {
            if (!this.verifyUUID(data.chatRoom.uuid) && data.chatRoom.uuid !== this.uuidChat) {
              this.uuidNew.push(data.chatRoom.uuid);
            }
        this.audio.nativeElement.play();
        setTimeout(() => {
          this.audio.nativeElement.pause();
          this.audio.nativeElement.currentTime = 0;
        }, 2000);
      }
    });
    this.socket.on(`newChat:${this.userId}`, (data) => {
      this.getChats();
    });
  }
  ngOnDestroy() {

  }
  verifyUUID(uuid): boolean {
    return this.uuidNew.includes(uuid);
  }
  queryRoute(obj) {
    const urlTree = this.router.createUrlTree([], {
      queryParams: obj,
      queryParamsHandling: 'merge',
      preserveFragment: true });

    this.router.navigateByUrl(urlTree);
  }
  getChats() {
    this.activeMsg = false;
    this.chatService.chats().subscribe((data) => {
      this.chats = data.data;
    });
  }
  async getMsg(chat?, uuid?) {
    if (chat) {
      this.chatSelected = chat;
    } else {
      this.chatService.chatOne(uuid).subscribe((data) => {
        this.chatSelected = data.data;
      });
    }
    this.uuidChat = (chat) ? chat.uuid : uuid;
    this.activeMsg = true;
    this.uuidNew.map((data, index) => {
      if (data === this.uuidChat) {
        this.uuidNew.splice(index, 1);
      }

    });
    this.queryRoute({uuidChat: this.uuidChat});
    this.chatService.messages(this.uuidChat).subscribe((data) => {
      this.messages = data.data.data;
      this.scrollToBottom();
    });
  }
  open() {
    this.modalRef = this.modalService.show(this.elementView);
  }
  addMessage(message, e? , button = false) {
    const newMessage = {
      text: message,
    };
    if (button || (e && e.keyCode === 13 && !(e.shiftKey && e.keyCode === 13))) {
      if (e) {
        e.preventDefault();
      }
      this.chatService.addMessages(this.uuidChat, newMessage).subscribe(() => {
        this.getMsg(null, this.uuidChat);
        this.scrollToBottom();
        this.message = '';
      });
    }
  }
  async scrollToBottom() {
    setTimeout(() => {
      try {
         this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      } catch (err) { }
    }, 50);
  }

 }
