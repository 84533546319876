import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import { Product } from '../../../model/Product';
import { Category } from '../../../model/Category';
import { CategoryService } from '../../../services/categories.services';
import { CountryService } from '../../../services/country';
import { ProductService } from '../../../services/product.services';
import { UnitService } from '../../../services/units.service';
import { ShippingsService } from '../../../services/shippings.service';
import { PaymentTypeService } from '../../../services/paymentType.services';
import { FileUploader } from 'ng2-file-upload';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Config } from '../../../config/config';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-product',
  templateUrl: './createProduct.component.html',
  styleUrls: ['../../../styles/main.scss'],
  providers: [CategoryService, ProductService, UnitService, ShippingsService, PaymentTypeService]
})
export class CreateProductComponent implements OnInit {
  wirzadNumber: any;
  loginForm: NgForm;
  selectedWirzad: any;
  product: Product;
  categories: Array<Category>;
  subCategories: any;
  subSubCategories: any;
  activeSubcategory: boolean;
  activeSubSubcategory: boolean;
  categorySelected: number;
  subCategorySelected: number;
  uploader: FileUploader;
  imagePreview: any;
  hasBaseDropZoneOver: boolean;
  imageUrlPath: any;
  img: any;
  cities: any;
  countries: any;
  shippings: any;
  units: any;
  payMode: any;
  varSubscribe: any;
  paramsRoute: any;
  uuidProduct: any;
  constructor(private categoryService: CategoryService,
              public sanitizer: DomSanitizer,
              private productService: ProductService,
              private router: Router,
              private route: ActivatedRoute,
              private countryService: CountryService,
              private shippingsService: ShippingsService,
              private unitsService: UnitService,
              private paymentTypeService: PaymentTypeService,
              private toastr: ToastrService) {
                this.wirzadNumber = [
                  {name: 'information_general', active: true},
                  {name: 'description', active: false},
                  {name: 'Transporte', active: false},
                ];

  }
  async ngOnInit() {
    this.activeSubcategory = false;
    this.product = new Product();
    this.shippingsService.getShippingTypes().subscribe((data) => {
      this.shippings = data.data.types;
    });
    this.paymentTypeService.get().subscribe((data) => {
      this.payMode = data.data;
    });
    this.unitsService.getAll().subscribe((data) => {
      this.units = data.data;
    });
    this.categoryService.get().subscribe((data) => {
      this.categories = data.data;
    });
    this.imagePreview = [];
    this.img = [];
    if (localStorage.getItem(Config.paramsQuery)) {
      this.paramsRoute = await JSON.parse(localStorage.getItem(Config.paramsQuery));
    }
    this.uuidProduct = (this.route.snapshot.queryParamMap.get('uuid')) ?
    this.route.snapshot.queryParamMap.get('uuid') : (this.paramsRoute) ? this.paramsRoute.uuid : false;
    if (this.uuidProduct) {
      this.productService.getProduct(this.uuidProduct).subscribe((data) => {
        if (this.route.snapshot.queryParamMap.get('selectedWizard')) {
          this.activeWirzad(this.route.snapshot.queryParamMap.get('selectedWizard'));
        }
        this.product = data.data;
        this.selectCategory(data.data.category.parentCategory.id, data.data.category.id);
        this.product.countryManufacturing = (this.product.manufacturingCity) ? this.product.manufacturingCity.country.id : null;
        this.product.countryCellar = (this.product.countryCellar) ?  this.product.countryCellar.id : null;
        this.product.unit = (this.product.unit) ?  this.product.unit.id : null;
        this.product.shippingType = (this.product.shippingType) ?  this.product.shippingType.id : null;
        this.product.payMode = (this.product.payMode) ?  this.product.payMode.id : null;
        if (this.product.countryManufacturing) {
          this.getCities(this.product.countryManufacturing);
        }
        this.product.manufacturingCityId = (this.product.manufacturingCity) ?  this.product.manufacturingCity.id : null;
        if (this.product.productAttachments.length) {
          this.product.productAttachments.map((obj) => {
            this.imagePreview.push(Config.urlAttachments + obj.url);
            this.img.push(obj);
          });
        }
      });
    }
    this.countryService.getCountries(null, 300).subscribe((data) => {
      this.countries = data.data.data;
    });
    this.uploader = this.fileConfig(['image/jpeg', 'image/png']);
    this.uploader.onAfterAddingFile = (fileItem) => {
      fileItem.withCredentials = false;
      this.imageUrlPath  = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
      this.imagePreview.push(this.imageUrlPath.changingThisBreaksApplicationSecurity);
    };
    this.selectedWirzad = 0;
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const responsePath = JSON.parse(response);
      this.img.push(responsePath.data.attachment);
      if (!this.uuidProduct) {
        if (this.img.length === this.imagePreview.length) {
          this.product.attachmentIds = [...this.img.map(img => img.id)];
          this.product.categoryId = this.subCategorySelected;
          if (typeof this.product.profitability === 'string') {
            this.product.profitability = Number(this.product.profitability.replace('%', ''));
          }
          this.productService.addProduct(this.product).subscribe((res) => {
            this.uuidProduct = res.data.uuid;
            this.toastr.success('producto creado con exito');
            this.activeWirzad((this.selectedWirzad == (this.wirzadNumber.length - 1)) ? this.selectedWirzad : Number(this.selectedWirzad) + 1);
            this.queryRoute({ uuid: res.data.uuid });
            this.uploader.queue = [];
          });
        }
      } else {
        if (this.img.length === this.imagePreview.length) {
          this.product.attachmentIds = [...this.img.map(img => img.id)];
          this.product.categoryId = this.subCategorySelected;
          if (typeof this.product.profitability === 'string') {
            this.product.profitability = Number(this.product.profitability.replace('%', ''));
          }
          if (this.product.initialStock && !this.product.stockCurrent) {
            this.product.stockCurrent = this.product.initialStock;
          }
          this.productService.editProduct(this.product, this.uuidProduct).subscribe((put) => {
            this.toastr.success('producto actualizado con exito');
            this.uuidProduct = put.data.uuid;
            this.activeWirzad((this.selectedWirzad == (this.wirzadNumber.length - 1)) ? this.selectedWirzad : Number(this.selectedWirzad) + 1);
            this.queryRoute({ uuid: put.data.uuid });
          });
        }
      }

    };
  }
  return() {
    if (localStorage.getItem(Config.returnUrl)) {
      this.router.navigate([localStorage.getItem(Config.returnUrl)]);
    }
  }
  queryRoute(obj) {
    const urlTree = this.router.createUrlTree([], {
      queryParams: obj,
      queryParamsHandling: 'merge',
      preserveFragment: true });

    this.router.navigateByUrl(urlTree);
  }
  getCities(id) {
    this.countryService.getCities(id, null, 1000).subscribe((data) => {
      this.cities = data.data.data;
    });
  }
  imgSanitizer(file) {
    this.imageUrlPath  = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file)));
    return this.imageUrlPath.changingThisBreaksApplicationSecurity;
  }
  removeImg(index) {
    if (this.uploader.queue.length) {
      this.uploader.queue[index].remove();
    }
    if (this.imagePreview.length === this.img.length) {
      this.imagePreview.splice(index, 1);
      this.img.splice(index, 1);
    } else {
      this.imagePreview.splice(index, 1);
    }
  }
  ClickFile(file: string) {
    document.getElementById(file).click();
  }
  activeWirzad(i) {
    this.selectedWirzad = i;
    this.queryRoute({ uuid: this.uuidProduct, selectedWizard: i});
    this.wirzadNumber.map((obj, index) => {
      if (index == i) {
        obj.active = true;
      } else {
        obj.active = false;
      }
    });
  }
  fileConfig(FileType?: string[]) {
    const option = {
      allowedMimeType: FileType,
      url: Config.api + Config.endpoints.uploadImage,
    };
    if (!FileType) {
      delete option.allowedMimeType;
    }
    return new FileUploader(option);
  }
  createProduct(data) {
    if (!this.uuidProduct) {
      if (this.img.length) {
        this.product.attachmentIds = [...this.img.map(img => img.id)];
        this.product.categoryId = this.subCategorySelected;
        if (typeof this.product.profitability === 'string') {
          this.product.profitability = Number(this.product.profitability.replace('%', ''));
        }
        this.productService.addProduct(data.data).subscribe((res) => {
          this.uuidProduct = res.data.uuid;
          this.toastr.success('producto creado con exito');
          this.activeWirzad((this.selectedWirzad == (this.wirzadNumber.length - 1)) ? this.selectedWirzad : Number(this.selectedWirzad) + 1);
          this.queryRoute({ uuid: res.data.uuid });
        });
      } else {
        this.uploader.uploadAll();
      }
    } else {
      if (!this.uploader.queue.length) {
        this.product.attachmentIds = [...this.img.map(img => img.id)];
        this.product.categoryId = this.subCategorySelected;
        if (typeof this.product.profitability === 'string') {
          this.product.profitability = Number(data.data.profitability.replace('%', ''));
        }
        if (this.product.initialStock && !this.product.stockCurrent) {
          this.product.stockCurrent = this.product.initialStock;
        }
        this.productService.editProduct(this.product, this.uuidProduct).subscribe((put) => {
          this.toastr.success('producto actualizado con exito');
          this.uuidProduct = put.data.uuid;
          this.queryRoute({ uuid: put.data.uuid, selectedWizard: this.selectedWirzad});
        });
      } else {
        this.uploader.uploadAll();
      }
    }

  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  selectCategory(id, idSub) {
    this.categorySelected = id;
    this.subCategorySelected = 0;
    this.categoryService.getSubcategoriesByCategory(id).subscribe((data) => {
      this.activeSubcategory = true;
      this.subCategories = data.data.subCategories;
      if (idSub) {
        this.subCategorySelected = idSub;
      }
    });
  }
  selectSubCategory(id) {
    this.subCategorySelected = id;
  }
  selectSubSubcategory(id) {

  }
}
