import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { QueryBuilder } from './QueryBuilder';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getAllGenerated(page = 1, take = 10, keyword?: any) {
    let objQuery: any = { page, take };
    // tslint:disable-next-line: no-unused-expression
    keyword && (objQuery = {...objQuery, keyword });

    const params: HttpParams = QueryBuilder.queryToHttpParams(QueryBuilder.verifyObject(objQuery));
    return this.httpClient.get(Config.api + Config.endpoints.ordersGenerated, {params});
  }

  getAllReceived(page = 1, take = 10, keyword?: any) {
    let objQuery: any = { page, take };
    // tslint:disable-next-line: no-unused-expression
    keyword && (objQuery = {...objQuery, keyword });

    const params: HttpParams = QueryBuilder.queryToHttpParams(QueryBuilder.verifyObject(objQuery));
    return this.httpClient.get(Config.api + Config.endpoints.ordersReceived, {params});
  }

  getOneGenerated(id: any) {
    return this.httpClient.get(Config.api + Config.endpoints.ordersGeneratedById.replace(':id', id));
  }

  getOneReceived(id: any) {
    return this.httpClient.get(Config.api + Config.endpoints.ordersReceivedById.replace(':id', id));
  }

  create() {

  }

  update() {

  }

  delete() {

  }
}
