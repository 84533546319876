import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Config } from '../../../app/config/config';
import { Location } from '@angular/common';

@Component({
  selector: 'app-pagination',
  templateUrl: './paginate.component.html',
  styleUrls: ['../../styles/main.scss']
})
export class PaginateComponent {
  @Input() currentData: any;
  @Output() currentPage = new EventEmitter();
  arrayPaginator: number[];
  constructor(private router: Router) {
  }
  // ngOnInit() {
  //   if (this.currentData) {
  //     console.log(this.range(10, this.currentData.totalPages / 10 * this.currentData.page));

  //     this.range(((this.currentData.totalPages < 10) ? this.currentData.totalPages : 10),
  //     this.currentData.totalPages / 10 * this.currentData.currentPage);
  //   }
  // }

  // range(size: number, startAt = 0) {
  //   this.arrayPaginator = [...Array.from(Array(size).keys())].map(i => i + startAt);
  // }

  arrayNew(number) {
    const array = [];
    for (let i = 1; i <= number; i++) {
      array.push(i);
    }
    return array;
  }

  pageCurrent(page) {
    // console.log(page);
    // console.log(this.currentData.totalPages);
    // this.range(((this.currentData.totalPages < 10) ? this.currentData.totalPages : ((this.currentData.currentPage + 10 < this.currentData.totalPages) ? 10 : (this.currentData.totalPages-this.currentData.currentPage))),
    // this.currentData.currentPage);
    // console.log(this.arrayPaginator);

    this.currentPage.emit(page);
  }
 }
