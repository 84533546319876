import { Component } from '@angular/core';
import { Config } from 'src/app/config/config';
import { Country } from 'src/app/model/Country';
import { CountryService } from 'src/app/services/country';
import { ListComponentInterface, ListActions } from 'src/app/components/lists/list.component';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: '../../../../components/common/templates/app-list-template.html',
  styleUrls: ['../../../../styles/main.scss']
})
export class ListCountriesComponent implements ListComponentInterface {
  actions: ListActions[];
  activateSeeker: boolean;
  activateSelectParent: boolean;
  columns: string[];
  current: any;
  data: Array<Country>;
  deleteAlertMessage: string;
  id: number;
  open: boolean;
  page: number;
  parentData?: Array<any>;
  parent?: string;
  tableId: string;
  title: string;

  constructor(
    private countryService: CountryService,
    private location: Location,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.actions = [ListActions.create, ListActions.update];
    this.activateSeeker = true;
    this.activateSelectParent = false;
    this.columns = ['name', 'code', 'active'];
    this.deleteAlertMessage = 'sure_delete_country';
    this.open = false;
    this.tableId = 'id';
    this.title = 'countries';
    this.getCountries();
  }

  getCountries(page?, take = 10, keyword?: string) {
    // tslint:disable-next-line: no-unused-expression
    page && (this.page = page);
    this.countryService.getCountries(page, take, keyword).subscribe(data => {
      this.data = data.data.data;
      this.current = data.data;
    });
  }

  pageCurrent(page) {
    this.getCountries(page);
  }

  create() {
    this.update(null);
  }

  delete(obj: { id: any, confirmed: boolean }) {
    this.id = (obj.id) ? obj.id : this.id;
    if (obj.confirmed) {
      this.countryService.delete(this.id).subscribe((put) => {
        this.getCountries(this.page);
        this.toastr.success(this.translate.instant('deleted_country'));
      });
    } else {
      this.open = !this.open;
    }
  }

  async update(id) {
    const route = this.location.path().replace('/', '').split('?').shift();
    localStorage.setItem(Config.returnUrl, route);
    this.router.navigate([Config.router.createCountry], { queryParams: { id } });
  }

  search(keyword: string) {
    this.getCountries(null, 10, keyword);
  }

  view(id: string | number) { }

}
