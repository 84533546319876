import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../../../app/config/config';
import { Location } from '@angular/common';
import { Product } from '../../../model/Product';
import { ProductService } from '../../../services/product.services';
import { ToastrService } from 'ngx-toastr';
import { ShoppingCartService } from 'src/app/services/shoppingCart.service';
import { BaseComponent } from '../../base/base.component';
import { GlobalState } from 'src/app/services/events.service';

@Component({
  templateUrl: './details.component.html',
  styleUrls: ['../../../styles/main.scss'],
  providers: [ShoppingCartService]
})
export class DetailsProductComponent {
  product: Product;
  uuidProduct: any;
  urlBack: string;
  quantity: number = 1;
  imgProduct: string;
  config = Config;
  constructor(
    private productService: ProductService,
    private router: Router,
    private toastr: ToastrService,
    public location: Location,
    private route: ActivatedRoute,
    private shoppingCartService: ShoppingCartService,
    public _state: GlobalState,
  ) {
    this.product = new Product();
    this.urlBack = Config.api + 'attachments/';
    this.uuidProduct = this.route.snapshot.queryParamMap.get('uuid');
    this.getProduct(this.uuidProduct);
  }
  contactProduct() {
    const body = { productUUID: this.uuidProduct};
    this.productService.contactProduct(body).subscribe(() => {
      this.router.navigate([Config.router.chat]);
    });
  }
  operationQuantity(operation) {
    if (operation) {
      this.quantity = Number(this.quantity) + 1;
    } else {
      this.quantity = Number(this.quantity) - 1;
    }
  }
  async co2() {
    return Math.floor(Math.random() * 100) + 1;
  }
  getProduct(uuid) {
    this.productService.getProduct(uuid).subscribe((data) => {
      this.product = data.data;
      if (this.product.productAttachments.length) {
        this.imgProduct = this.product.productAttachments[0].url;
      }
    });
  }
  goChat() {
    this.router.navigate([Config.router.chat]);
  }
  addCar(uuid, quantity) {
    this.shoppingCartService.addProduct({ uuid, quantity: Number(quantity)})
      .subscribe(res => {
        this._state.replaySubjectChanged('car', res);
        this.toastr.success('Agregado al carro', '');
      });
  }
}
