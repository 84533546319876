import { Model } from './Model';
import { Currency } from './Currency';
import { User } from './UserModel';
import { Supplier } from './Supplier';
import { Category } from './Category';
import { Unit } from './Unit';
import { Attachment } from './Attachment';

export class Product extends Model {
    public id: number;
    public uuid: string;
    public code: string;
    public name: string;
    public moq: number;
    public unitMoq: number;
    public description: string;
    public comments: string;
    public goodpoints: number;
    public salePrice: number;
    public logisticsCost: number;
    public fobPrice: number;
    public internationalizationCost: number;
    public marginSale: number;
    public weight: number;
    public height: number;
    public width: number;
    public depth: number;
    public percentageGoodpoints: number;
    public salesMarginPercentage: number;
    public procentajeLogisticsCost: number;
    public initialStock: number;
    public stockCurrent: number;
    public unit2: number;
    public unit3: number;
    public guarantee: string;
    public discountPrice: number;
    public brand: string;
    public premiumDiscountPrice: number;
    public priceCost: number;
    public codeHS: string;
    public documentIES: string;
    public profitability: any;
    public published: any;
    public rejectedByAdmin: any;
    public isDeleted: any;
    public createdAt: Date;
    public category: any;
    public unit: any;
    public currency: Currency;
    public paymentType: any;
    public productionType: any;
    public shippingType: any;
    public countryManufacturing: any;
    public countryCellar: any;
    public user: User;
    public supplier: Supplier;
    public attachmentIds: any;
    public categoryId: number;
    public countryCellarId: number;
    public certifications: string;
    public productAttachments: Array<Attachment>;
    public isNational: any;
    public payMode: any;
    public manufacturingCity: any;
    public manufacturingCityId: any;

}
