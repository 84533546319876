import { HttpParams } from '@angular/common/http';

export class QueryBuilder {

  static queryToHttpParams(query: object) {
    if (query) {
      return Object.getOwnPropertyNames(query)
        .reduce((previous, key) => previous.set(key, query[key]), new HttpParams());
    }
  }
  static verifyObject(object) {
    Object.keys(object).map(function (value, key) {
      if (object[value] === undefined || object[value] === null || object[value].length === 0) {
        delete object[value];
      }
    });
    return object;
  }
}
