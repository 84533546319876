import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  constructor(
    private httpClient: HttpClient
  ) { }
  addProduct(product): Observable<any> {
    return this.httpClient.post(`${Config.api}shopping-carts/add-one`, product);
  }

  getShoppingCart(): Observable<any> {
    return this.httpClient.get(`${Config.api}shopping-carts`);
  }

  changeQunatity(uuidProduct, quantity): Observable<any> {
    return this.httpClient.post(`${Config.api}shopping-carts/change-quantity`, { uuid: uuidProduct, quantity });
  }

  process(order): Observable<any> {
    return this.httpClient.post(`${Config.api}shopping-carts/process`, order);
  }

  removeOne(uuidProduct): Observable<any> {
    return this.httpClient.delete(`${Config.api}shopping-carts/${uuidProduct}/remove-one`);
  }

}
