import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CreationComponentInterface } from '../../../components/componentCreation/creation.component';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../../../services/user.services';

@Component({
  templateUrl: '../../../components/common/templates/create.component.html',
  styleUrls: ['../../../styles/main.scss'],
  providers: [UsersService]
})
export class CreateUserComponent implements OnInit, CreationComponentInterface {
  buttonPosition: any;
  columns: any;
  data: any;
  formantView: number;
  id: string | number;
  instance: any;
  rules: any;
  title: string;
  validateInputs: boolean;

  constructor(
    private userService: UsersService,
    private location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.id = this.route.snapshot.queryParamMap.get('id');
    if (this.id) {
      this.title = 'update_user';
      this.userService.usersGet(this.id).subscribe((data: { data }) => {
        this.instance = data.data.user;
      });
    } else {
      this.title = 'create_user';
    }
    this.columns = [
      {
        name: 'name',
        type: 'input',
      },
      {
        name: 'lastname',
        type: 'input',
      },
      {
        name: 'username',
        type: 'input',
      },
      {
        name: 'email',
        type: 'input',
      },
      {
        name: 'password',
        type: 'password',
      },
      {
        name: 'phone',
        type: 'input',
      },
      {
        name: 'position',
        type: 'input',
      },
      {
        name: 'userType',
        type: 'select',
        data: []
      },
      {
        name: 'active',
        type: 'select',
        data: [
          { value: 0, name: 'no' },
          { value: 1, name: 'yes' }
        ]
      },
    ];
    this.rules = {
      email: {
        required: true,
        // tslint:disable-next-line: max-line-length
        pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    };
  }

  async ngOnInit() {
    this.userService.getUserTypes().subscribe((types: any) => {
      this.columns[7].data = types.data.map(element => {
        return { ...element, value: element.name };
      });
    });
  }

  save(data: any) {
    if (!this.id) {
      this.userService.create(data).subscribe(user => {
        this.toastr.success(this.translate.instant('user_created'));
        this.location.back();
      }, (error) => this.toastr.error(error.message));
    } else {
      this.userService.update(data).subscribe(user => {
        this.toastr.success(this.translate.instant('updated_user'));
        this.location.back();
      }, (error) => this.toastr.error(error.message));
    }
  }
}
