import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { QueryBuilder } from './QueryBuilder';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getProducts(page = 1, take = 10, keyword?, categoryId?, subCategoryId?, minPrice?, maxPrice?): Observable<any> {
    let objQuery: any = { page, take };
    // tslint:disable-next-line: no-unused-expression
    keyword && (objQuery = { ...objQuery, keyword });
    // tslint:disable-next-line: no-unused-expression
    categoryId && (objQuery = { ...objQuery, categoryId });
    // tslint:disable-next-line: no-unused-expression
    subCategoryId && (objQuery = { ...objQuery, subCategoryId });
    // tslint:disable-next-line: no-unused-expression
    minPrice && (objQuery = { ...objQuery, minPrice });
    // tslint:disable-next-line: no-unused-expression
    maxPrice && (objQuery = { ...objQuery, maxPrice });

    const params: HttpParams = QueryBuilder.queryToHttpParams(QueryBuilder.verifyObject(objQuery));
    return this.httpClient.get(Config.api + Config.endpoints.products, {params});
  }

  getMyProducts(page = 1, take = 10, keyword?, categoryId?, subCategoryId?): Observable<any> {
    let objQuery: any = { page, take };
    // tslint:disable-next-line: no-unused-expression
    keyword && (objQuery = { ...objQuery, keyword });
    // tslint:disable-next-line: no-unused-expression
    categoryId && (objQuery = { ...objQuery, categoryId });
    // tslint:disable-next-line: no-unused-expression
    subCategoryId && (objQuery = { ...objQuery, subCategoryId });

    const params: HttpParams = QueryBuilder.queryToHttpParams(QueryBuilder.verifyObject(objQuery));
    return this.httpClient.get(Config.api + Config.endpoints.my_products, {params});
  }

  addProduct(product): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.products, QueryBuilder.verifyObject(product));
  }

  contactProduct(product): Observable<any> {
    return this.httpClient.post(Config.api + Config.endpoints.threadContact, QueryBuilder.verifyObject(product));
  }

  editProduct(product, uuid): Observable<any> {
    return this.httpClient.put(Config.api + Config.endpoints.products + uuid, QueryBuilder.verifyObject(product));
  }

  publish(uuid): Observable<any> {
    return this.httpClient.get(`${Config.api + Config.endpoints.products + uuid}/publish`);
  }

  getProduct(uuid): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.products + uuid);
  }
  search(search): Observable<any> {
    const params: HttpParams = QueryBuilder.queryToHttpParams(QueryBuilder.verifyObject({search}));
    return this.httpClient.get(Config.api + Config.endpoints.products + 'search', {params});
  }

}
