import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Country } from 'src/app/model/Country';
import { CountryService } from 'src/app/services/country';
import { CreationComponentInterface } from 'src/app/components/componentCreation/creation.component';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: '../../../../components/common/templates/create.component.html',
  styleUrls: ['../../../../styles/main.scss'],
  providers: [CountryService]
})
export class CreateCountryComponent implements OnInit, CreationComponentInterface {
  buttonPosition: any;
  columns: any;
  data: any;
  formantView: number;
  id: any;
  instance: Partial<Country>;
  rules: any;
  title: string;
  validateInputs: boolean;

  constructor(
    private countryService: CountryService,
    private location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.id = this.route.snapshot.queryParamMap.get('id');
    if (this.id) {
      this.title = 'update_country';
      this.countryService.getOne(this.id).subscribe((data) => {
        this.instance = data.data;
      });
    } else {
      this.title = 'create_country';
    }
  }

  async ngOnInit() {
    this.columns = [
      {
        name: 'name',
        type: 'input',
      },
      {
        name: 'code',
        type: 'input',
      },
      {
        name: 'active',
        type: 'select',
        data: [
          { name: 'yes', value: 1 },
          { name: 'no', value: 0 }
        ]
      },
    ];

  }

  save(data: Partial<Country>) {
    if (!this.id) {
      this.countryService.save(data).subscribe(unit => {
        this.toastr.success(this.translate.instant('country_created'));
        this.location.back();
      }, (error) => this.toastr.error(error.message));
    } else {
      this.countryService.update(data, this.id).subscribe(unit => {
        this.toastr.success(this.translate.instant('updated_country'));
        this.location.back();
      }, (error) => this.toastr.error(error.message));
    }
  }
}
