import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { GlobalState } from 'src/app/services/events.service';
import { User } from 'src/app/model/UserModel';
import { AuthService } from 'src/app/services/auth.services';
import { UsersService } from 'src/app/services/user.services';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['../../styles/main.scss'],
  providers: []
})
export class ProfileComponent implements OnInit, OnDestroy  {
  userData: User;
  payload: any;
  constructor(private authService: AuthService, private usersService: UsersService, private toastr: ToastrService) {
    this.userData = new User();
    this.payload = this.authService.payload();
    this.get();
  }
  ngOnInit() {
  }
  ngOnDestroy() {

  }
  get() {
    this.usersService.usersGet(this.payload.uuid).subscribe((data) => {
      this.userData = data.data.user;
    });
  }
  send(userData) {
    this.usersService.update(userData).subscribe((data) => {
      this.toastr.success('usuario actualizado con exito');
      this.get();
    });
  }
 }
