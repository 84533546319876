import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Unit } from '../model/Unit';

@Injectable({
  providedIn: 'root'
})
export class UnitService {
  constructor(
    private httpClient: HttpClient
  ) { }

  getAll(): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.units);
  }

  getOne(id: number): Observable<any> {
    return this.httpClient.get(Config.api + Config.endpoints.units + '/' + id);
  }

  save(unit: Unit) {
    return this.httpClient.post(Config.api + Config.endpoints.units, unit);
  }

  update(unit: Unit, id?: number) {
    return this.httpClient.put(Config.api + Config.endpoints.units + '/' + id, unit);
  }

  delete(id?: number) {
    return this.httpClient.delete(Config.api + Config.endpoints.units + '/' + id);
  }

}
