import { Component, OnDestroy } from '@angular/core';
import { ListComponentInterface, ListActions } from 'src/app/components/lists/list.component';
import { Location } from '@angular/common';
import { OrderService } from '../../../services/order.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Config } from 'src/app/config/config';
import * as moment from 'moment';

@Component({
  templateUrl: '../../../components/common/templates/app-list-template.html',
  styleUrls: ['../../../styles/main.scss']
})
export class ListOrdersComponent implements ListComponentInterface, OnDestroy {

  actions: ListActions[] = [];
  activateSeeker: boolean;
  activateSelectParent: boolean;
  childrensKey?: string;
  columns = [];
  current: any;
  data: any;
  deleteAlertMessage: string;
  groupSelectOptions?: boolean;
  open: boolean = false;
  page: number;
  parent?: any;
  parentData?: any[];
  tableId: string;
  title: string;

  constructor(
    private orderService: OrderService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    if (this.location.isCurrentPathEqualTo(`/${Config.router.ordersReceived}`)) {
      this.title = 'orders_received';
    } else {
      this.title = 'orders_generated';
    }
    this.data = [];
    this.actions = [ ListActions.update, ListActions.view ];
    this.activateSelectParent = false;
    this.activateSeeker = false;
    this.columns = [
      'id',
      'priceWithoutIva',
      'priceWithIva',
      'paidAmount',
      // 'paymentType',
      'items_quantity',
      'createdAt',
      // 'currency',
      // 'company',
    ];
    this.deleteAlertMessage = 'sure_delete_order';
    this.open = false;
    this.tableId = 'id';

    this.getOrders();
  }

  private async mapData(data) {
    return await data.map((order: any) => {
      return {
        ...order,
        createdAt: moment(order.createdAt).format('DD/MM/YYYY'),
        items_quantity: order.orderItems.length,
        company: order.company.businessName
      };
    });
  }
  ngOnDestroy(): void {
    this.data = [];
  }
  getOrders(page?) {
     // tslint:disable-next-line: no-unused-expression
     page && (this.page = page);
    if (this.location.isCurrentPathEqualTo(`/${Config.router.ordersReceived}`)) {
      this.orderService.getAllReceived(page).subscribe((data: any) => {
        this.data = data.data.orders.map((order: any) => {
          return {
            ...order,
            createdAt: moment(order.createdAt).format('DD/MM/YYYY'),
            items_quantity: order.orderItems.length,
            company: order.company.businessName
          };
        });
        this.current = data;
      });
    } else {
      this.orderService.getAllGenerated(page).subscribe((data: any) => {
        this.data = data.data.orders.map((order: any) => {
          return {
            ...order,
            createdAt: moment(order.createdAt).format('DD/MM/YYYY'),
            items_quantity: order.orderItems.length,
            company: order.company.businessName
          };
        });
        this.current = data;
      });
    }
  }

  create?: () => any;
  delete?: (event: any) => any;

  pageCurrent(page: number) {
    this.getOrders(page);
  }

  parentSelected?: (id: string | number) => any;
  search?: (keyword: string) => any;
  update?: (id: string | number) => any;
  view(id: string | number) {
    this.router.navigate([Config.router.orderDetail], {queryParams: {id}});
  }
}
