import { Model } from './Model';
import { Order } from './order';
import { ShippingItems } from './shippingItems';
import { Attachment } from './Attachment';

export class Shipping extends Model {
  public order: Order;
  public shippingStateId: number;
  public shippingTypeId: number;
  public portId: number;
  public eta: Date;
  public etd: Date;
  public claimProtocol: string;
  public week: number;
  public arrive: string;
  public arrivalDate: string;
  public containerNumber: number;
  public billNumber: number;
  public nameVessel: string;
  public brand: string;
  public salePrice: string;
  public brandColor: string;
  public quantity: number;
  public amountPaid: number;
  public shippingItems: ShippingItems;
  public Attachments: Attachment;
  public port: any;
  public shippingState: any;
  public shippingType: any;
}
